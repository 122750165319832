import {gql} from '@apollo/client';

export const GET_CARDS = gql`
    query getCards($filter: PresentationCompositeFilter, $type: PresentationOutputType!) {
        cards: presentations(filter: $filter,type: $type) {
            id
            buttons {
                id
                isHidden
                colorTheme
                link
                title
            }
            tag
            tagIsHidden
            tagTheme
            type
            link
            MTTypeIsHidden
            MTTypeTheme
            eventTypeIsHidden
            eventTypeTheme
            formatIsHidden
            formatTheme
            isHidden
            isHiddenForCourses
            isHiddenForLanding
            icons {
                id
                description
                iconImage
                isHidden
            }
            event {
                title
                format
                description
                files {
                    type
                    file {
                        url
                        name
                    }
                }
                MTType
                type
            }
            eventNote {
                title
                format
                description
                coverFile {
                    name
                    url
                }
                type
            }
        }
    }
`;
