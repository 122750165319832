import React, {useContext, useState, useEffect} from 'react';
import styled from '@emotion/styled';
import {TabPanel} from '@mui/lab';
import {Box, CircularProgress, Typography} from '@mui/material';
import NotificationCard from './NotificationCard';
import {useNotifications} from '@novu/notification-center';
import InfiniteScroll from 'react-infinite-scroll-component';
import {NotificationContext} from 'context/notificationContext';

const NotificationTabPanel = ({value}) => {
    const {markAsSeen} = useNotifications();
    const [hasNextPage, setHasNextPage] = useState(true);
    const [page, setPage] = useState(0);
    const {
        getNotifications,
        setNotifications,
        onlyUnread,
        notifications,
        loading
    } = useContext(NotificationContext);

    const markAllNotifications = () => {
        if (notifications && notifications.length)
            Promise.all(
                notifications.map((notification) => !notification?.seen && markAsSeen(notification['_id']))
            ).then(() =>
                setNotifications((notifications) =>
                    notifications.map((notification) => ({
                        ...notification,
                        seen: true
                    }))
                )
            );
    };

    useEffect(() => {
        setHasNextPage(true);
        setPage(0);
    }, [value, onlyUnread]);

    const onLoadMore = async () => {
        if (hasNextPage) {
            const newPage = page + 1;
            setPage(newPage);
            const data = await getNotifications({tab: value, onlyUnread, page: newPage});
            if (data.length < 10) setHasNextPage(false);
            setNotifications((prev) => [...prev, ...data]);
        }
    };

    return (
        <StyledTabPanel value={value} id={'menu'}>
            <Typography sx={{cursor: 'pointer'}} color={'primary'} mt={3} mb={1} mx={3} onClick={markAllNotifications}>
                ОТМЕТИТЬ ВСЕ КАК ПРОЧИТАННЫЕ
            </Typography>
            <InfiniteScroll
                dataLength={notifications?.length || 0} //This is important field to render the next data
                next={onLoadMore}
                hasMore={hasNextPage && !!notifications?.length || loading}
                loader={
                    <Box textAlign={'center'} pt={2}>
                        <CircularProgress/>
                    </Box>
                }
                style={{overflowY: 'hidden'}}
                scrollThreshold={'20px'}
                endMessage={
                    !notifications?.length && (
                        <Typography textAlign={'center'} my={2} p={1.5}>
                            Уведомлений нет
                        </Typography>
                    )
                }
                scrollableTarget={'menu'}>
                {notifications?.map((notification, key) => (
                    <NotificationCard notification={notification} key={key}
                                      isLast={notifications?.length === key + 1}/>
                ))}
            </InfiniteScroll>
        </StyledTabPanel>
    );
};

const StyledTabPanel = styled(TabPanel)(({theme}) => ({
    maxHeight: 380,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {width: 4},
    '&::-webkit-scrollbar-thumb': {width: 4, borderRadius: 4, backgroundColor: theme.palette.grey[400]}
}));

export default NotificationTabPanel;
