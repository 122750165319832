export const whiteTheme = {
    palette: {
        background: {
            default: '#FFFFFF',
            paper: '#FFFFFF',
            card: '#F0F1F5'
        },
        text: {
            primary: '#212227'
        },
        system: {
            50: '#F8F8FA',
            100: '#F4F5F9',
            200: '#F0F1F5',
            300: '#E4E6ED',
            400: '#D1D3DC',
            500: '#B6B8C1',
            600: '#9294A0',
            700: '#6E707A',
            800: '#4B4D58',
            850: '#333333',
            900: '#212227'
        },
        systemShift: {
            50: '#F8F8FA',
            100: '#F4F5F9',
            200: '#F0F1F5',
            300: '#E4E6ED',
            400: '#D1D3DC',
            500: '#B6B8C1',
            600: '#9294A0',
            700: '#6E707A',
            800: '#4B4D58',
            900: '#212227'
        },
        divider: '#D1D3DC',
        error: {
            main: '#FF564E',
            100: '#FFEEED'
        },
        warning: {
            main: '#F0B606',
            100: '#FFF9E5'
        },
        success: {
            main: '#28BC00',
            100: '#E9F8E5'
        },
        inProcess: {
            main: '#009EF6',
            100: '#E9F8E5'
        },

        primary: {
            active: '#7A45EA',
            main: '#956AEE',
            medium: '#BCA2F4',
            light: '#D3C3F8'
        },
        primaryShift: {
            active: '#956AEE',
            main: '#7A45EA',
            medium: '#BCA2F4',
            light: '#D3C3F8'
        },
        mode: 'light'
    }
};
