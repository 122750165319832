import React, {useContext} from 'react';
import {Avatar, Badge, Box, Stack, styled, Tab, Tooltip, Typography} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import {TabContext, TabList} from '@mui/lab';
import ChatHeader from './ChatHeader';
import {LockIcon} from 'assets/icons';
import {TinodeContext} from 'context/tinodeContext';
import {ROUTES} from 'constants/routes';
import CircularLoading from 'components/loading/CircularLoading';

export const CHAT_TYPE = {EVENT: 'EVENT', GLOBAL: 'GLOBAL', ALL: 'ALL'};

const Contacts = (props) => {
    const {filteredContacts, tab, handleChange = () => {}} = props;
    const {topic, messageUpdate, contactsLoading, isNewMessages} = useContext(TinodeContext);
    const navigate = useNavigate();
    const {chatId} = useParams();

    const TABS = [
        {title: 'Чаты', value: CHAT_TYPE.GLOBAL, isBadge: isNewMessages.GLOBAL},
        {title: 'Чаты событий', value: CHAT_TYPE.EVENT, isBadge: isNewMessages.EVENT}
    ];

    const handleClick = (contact) => {
        if (!!chatId) topic.current?.leave(false);
        messageUpdate(contact?.name);
        navigate(`${ROUTES.CHATS}/${contact?.name}`);
    };

    return (
        <Box width={'100%'}>
            <Stack
                justifyContent={'center'}
                height={75}
                sx={{px: 4, borderBottom: (theme) => '1px solid' + theme.palette.divider}}>
                <TabContext value={tab}>
                    <TabList onChange={(event, newTab) => handleChange(event, newTab)}>
                        {TABS?.map((tab, index) => (
                            <Tab
                                key={index}
                                label={
                                    <Badge
                                        invisible={!tab.isBadge}
                                        color='error'
                                        variant='dot'
                                        componentsProps={{badge: {sx: {top: 7, right: -9}}}}>
                                        {tab.title}
                                    </Badge>
                                }
                                value={tab.value}
                                sx={{minWidth: 60}}
                            />
                        ))}
                    </TabList>
                </TabContext>
            </Stack>
            <StyledChat>
                <CircularLoading loading={contactsLoading} sx={{pt: 3}}>
                    {filteredContacts?.length !== 0 ? (
                        filteredContacts?.map((contact, index) => (
                            <StyledContact
                                key={index}
                                direction={'row'}
                                spacing={2}
                                isSelectedContact={contact?.name === chatId}
                                onClick={() => (contact?.name !== chatId ? handleClick(contact) : undefined)}>
                                <ChatHeader contact={contact} maxLength={30} />
                                <Stack direction={'row'} alignItems={'center'} spacing={2}>
                                    {contact?.public?.tags?.isBlocked && (
                                        <Tooltip title={'Чат заблокирован'}>
                                            <Box display={'flex'} color={'systemShift.600'}>
                                                <LockIcon />
                                            </Box>
                                        </Tooltip>
                                    )}
                                    <Box width={30} height={30}>
                                        {!!contact.unread && (
                                            <Avatar
                                                sx={{
                                                    backgroundColor: 'primaryShift.active',
                                                    color: 'system.50',
                                                    width: '100%',
                                                    height: '100%'
                                                }}>
                                                <Typography fontSize={12}>
                                                    {contact.unread > 99 ? '99+' : contact.unread}
                                                </Typography>
                                            </Avatar>
                                        )}
                                    </Box>
                                </Stack>
                            </StyledContact>
                        ))
                    ) : (
                        <Stack alignItems={'center'} pt={3}>
                            <Typography>Нет чатов</Typography>
                        </Stack>
                    )}
                </CircularLoading>
            </StyledChat>
        </Box>
    );
};

export default Contacts;

const StyledChat = styled(Stack)(({theme}) => ({
    scrollBehavior: 'smooth',
    width: '100%',
    height: 500,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {width: 6},
    '&::-webkit-scrollbar-thumb': {width: 4, borderRadius: 4, backgroundColor: theme.palette.grey[400]}
}));
const StyledContact = styled(Stack, {shouldForwardProp: (prop) => prop !== 'isSelectedContact'})(
    ({isSelectedContact, theme}) => ({
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: isSelectedContact ? theme.palette.systemShift[50] : 'initial',
        borderBottom: '1px solid' + theme.palette.divider,
        '&:last-child': {borderBottom: 0},
        paddingRight: theme.spacing(1.5),
        cursor: 'pointer'
    })
);
