import React, {useEffect, useState} from 'react';
import {Stack, Typography} from '@mui/material';
import AutocompleteControl from './AutocompleteControl';
import DatePickerControl from './DatePickerControl';
import RadioGroupControl from './RadioGroupControl';
import CheckboxGroupControl from './CheckboxGroupControl';
import SelectControl from './SelectControl';
import TextFieldControl from './TextFieldControl';
import {hyphenate} from 'hyphen/ru';

export const INPUT_TYPE = {
    TEXT: 'TEXT',
    AUTOCOMPLETE: 'AUTOCOMPLETE',
    DATE: 'DATE',
    RADIO: 'RADIO',
    CHECKBOX: 'CHECKBOX',
    SELECT: 'SELECT',
    CUSTOM: 'CUSTOM'
};

const generateInput = (props) => {
    switch (props.type) {
        case INPUT_TYPE.TEXT:
            return <TextFieldControl {...props} />;
        case INPUT_TYPE.AUTOCOMPLETE:
            return <AutocompleteControl {...props} />;
        case INPUT_TYPE.DATE:
            return <DatePickerControl {...props} />;
        case INPUT_TYPE.RADIO:
            return <RadioGroupControl {...props} />;
        case INPUT_TYPE.CHECKBOX:
            return <CheckboxGroupControl {...props} />;
        case INPUT_TYPE.SELECT:
            return <SelectControl {...props} />;
    }
};

const InputWithLabel = ({
    label,
    labelTopAlignment,
    labelWidth = 150,
    children,
    component,
    wrapperSx,
    verticalLabel,
    labelHyphen = false,
    ...rest
}) => {
    const [newLabel, setNewLabel] = useState(label || '');
    useEffect(() => {
        if (labelHyphen) hyphenate(label, {minWordLength: 8}).then((res) => setNewLabel(res));
    }, [label]);

    return (
        <Stack
            direction={verticalLabel ? 'column' : 'row'}
            alignItems={labelTopAlignment || verticalLabel ? 'flex-start' : 'center'}
            spacing={verticalLabel ? 1.5 : 1}
            sx={wrapperSx}>
            {label && (
                <Typography
                    minWidth={labelWidth}
                    maxWidth={labelWidth}
                    sx={{wordBreak: 'break-word', textTransform: 'uppercase', hyphens: 'auto'}}
                    mt={labelTopAlignment ? 0.5 : 0}>
                    {newLabel}
                </Typography>
            )}
            {generateInput({...rest, label}) || component}
        </Stack>
    );
};

export default InputWithLabel;
