import PropTypes from 'prop-types';
import React from 'react';
import {TextField} from '@mui/material';
import {Controller} from 'react-hook-form';
import 'moment/locale/ru';
import moment from 'moment';
import {DatePicker} from '@mui/x-date-pickers';
import {CalendarIcon} from './icons';

const DatePickerControl = ({
    control,
    name,
    label,
    placeholder,
    inputFormat,
    inputProps,
    views,
    onAccept,
    defaultValue = null,
    ...rest
}) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({field: {ref, ...props}, fieldState: {error}}) => (
                <DatePicker
                    {...rest}
                    toolbarTitle={'Выберите дату'}
                    inputFormat={inputFormat || 'DD.MM.yyyy'}
                    views={views || undefined}
                    components={{
                        OpenPickerIcon: CalendarIcon
                    }}
                    onAccept={onAccept}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            helperText={error?.message}
                            sx={{width: '100%'}}
                            value={moment(props.value).format(inputFormat || 'DD.MM.yyyy')}
                            inputProps={{
                                ...(inputProps ? inputProps : params.inputProps),
                                placeholder: placeholder || 'дд.мм.гггг'
                            }}
                            error={!!error}
                            size={rest.size}
                        />
                    )}
                    {...props}
                />
            )}
        />
    );
};

export default DatePickerControl;

DatePickerControl.propTypes = {
    control: PropTypes.object.isRequired,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    size: PropTypes.string
};

DatePickerControl.defaultProps = {
    size: 'medium'
};
