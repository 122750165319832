import React from 'react';
import {Box, Button, Container, Grid, Stack, Typography, useMediaQuery} from '@mui/material';
import {useQuery} from '@apollo/client';
import {GET_BLOG_POSTS} from 'graphql/blog/getBlogPosts';
import BlogCard from 'components/BlogCard';
import {useNavigate} from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import {Dot, LeftArrow, RightArrow} from 'components/CarouselComponents';
import {ROUTES} from 'constants/routes';
import CircularLoading from 'components/loading/CircularLoading';
import {POSTS_TYPE_ENUM} from 'constants/blog';

const RESPONSIVE_BREAKPOINTS = {
    desktop: {
        breakpoint: {max: 4000, min: 1200},
        items: 1,
        slidesToSlide: 1
    },
    tablet: {
        breakpoint: {max: 1200, min: 600},
        items: 1,
        slidesToSlide: 1
    },
    mobile: {
        breakpoint: {max: 600, min: 0},
        items: 1,
        slidesToSlide: 1
    }
};

const Blog = () => {
    const navigate = useNavigate();
    const {data, loading, error} = useQuery(GET_BLOG_POSTS, {
        variables: {
            page: 0,
            limit: 4,
            filterInput: {
                AND: {isHidden: {value: false}}
            }
        }
    });
    const isMd = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const posts = data?.blogPosts?.data || [];
    return (
        <Container sx={{my: 5, position: 'relative'}}>
            <Typography variant='h3' mb={7} sx={{textAlign: {xs: 'center', lg: 'left'}}}>
                БЛОГ
            </Typography>
            <CircularLoading loading={loading || error}>
                {isMd ? (
                    <Grid container alignContent={'center'} rowSpacing={2} columnSpacing={2}>
                        {posts?.map((post) => (
                            <Grid item md={6} xs={'auto'}>
                                <BlogCard post={post} key={post?.id} />
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Box
                        sx={{
                            borderRadius: '20px',
                            overflow: 'hidden',
                            mx: {xs: 3, sm: 15},
                            '& .item': {px: 3},
                            '& .container': {mx: -3, mb: 6, position: 'static'},
                            '& .react-multi-carousel-dot-list': {
                                overflow: 'hidden',
                                maxWidth: 400,
                                margin: 'auto'
                            }
                        }}>
                        <Carousel
                            responsive={RESPONSIVE_BREAKPOINTS}
                            showDots={true}
                            autoPlay={false}
                            shouldResetAutoplay={false}
                            infinite={true}
                            removeArrowOnDeviceType={[]}
                            customLeftArrow={<LeftArrow left={{xs: 15, sm: 60}} />}
                            customRightArrow={<RightArrow right={{xs: 15, sm: 60}} />}
                            customDot={<Dot />}
                            // renderButtonGroupOutside={true}
                            itemClass='item'
                            containerClass='container'>
                            {posts?.map((post) => (
                                <BlogCard post={post} key={post?.id} />
                            ))}
                        </Carousel>
                    </Box>
                )}
            </CircularLoading>
            <Stack mx={'auto'} my={4} alignItems={'center'}>
                <Button variant={'contained'} size={'large'} onClick={() => navigate(ROUTES.BLOG)}>
                    Смотреть все материалы
                </Button>
            </Stack>
        </Container>
    );
};

export default Blog;
