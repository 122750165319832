import React, {useContext, useEffect, useState} from 'react';
import {useUnseenCount} from '@novu/notification-center';
import {Badge, Box, IconButton, Menu, useMediaQuery} from '@mui/material';
import NotificationTabs from 'layouts/MainLayout/Header/components/NotificationTabs';
import {NotificationIcon} from 'assets/icons';
import AchievementModal from 'pages/Trajectory/componets/Achievements/components/AchievementModal';
import {NotificationContext} from 'context/notificationContext';
import {NOTIFICATIONS_TAB_TYPE_ENUM} from 'constants/notifications';
import {useLazyQuery} from "@apollo/client";
import {GET_ACHIEVEMENTS} from "graphql/achievements/query/getAchievements";
import {useLocation} from "react-router-dom";

const Notifications = ({navbar}) => {
    const {unseenCount} = useUnseenCount();
    const {pathname} = useLocation();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const {getNotifications} = useContext(NotificationContext);
    const [achievement, setAchievement] = useState(null);
    const [getAchievements] = useLazyQuery(GET_ACHIEVEMENTS);

    useEffect(async () => {
        const achievements = await getNotifications({tab: NOTIFICATIONS_TAB_TYPE_ENUM.ACHIEVEMENTS, onlyUnread: false});
        if (achievements?.length > 0 && !achievements?.[0]?.seen) {
            setAchievement({...achievements[0].payload, _id: achievements[0]._id});
            setOpen(true);
            if (pathname.includes('trajectory')) getAchievements()
        }
    }, [unseenCount]);
    const isLg = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    const handleOpenMenu = (e) => {
        setAnchorEl(isLg ? e.currentTarget : navbar.current);
    };

    const handleCloseMenu = () => setAnchorEl(null);
    return (
        <>
            <Box>
                <Badge badgeContent={unseenCount} color="error" componentsProps={{badge: {sx: {top: 4, right: 4}}}}>
                    <IconButton
                        color="inherit"
                        onClick={handleOpenMenu}
                        sx={{color: !!anchorEl ? 'primaryShift.main' : 'systemShift.600'}}>
                        <NotificationIcon width={20} height={21}/>
                    </IconButton>
                </Badge>
                {isLg ? (
                    <Menu
                        anchorEl={anchorEl}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                        transformOrigin={{vertical: 'top', horizontal: 'center'}}
                        PaperProps={{
                            sx: {
                                border: 'solid 3px',
                                borderColor: 'primary.medium',
                                borderRadius: '25px !important',
                                boxShadow: '0px 4px 25px rgba(104, 104, 104, 0.25)',
                                width: 500,
                                mt: 1
                            }
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}>
                        <NotificationTabs/>
                    </Menu>
                ) : (
                    <Menu
                        anchorEl={anchorEl}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                        transformOrigin={{vertical: 'top', horizontal: 'center'}}
                        PaperProps={{
                            sx: {
                                left: '0 !important',
                                maxWidth: '100%',
                                width: '100%',
                                top:
                                    navbar.current &&
                                    `${navbar.current.getBoundingClientRect().bottom}px !important`
                            }
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}>
                        <NotificationTabs/>
                    </Menu>
                )}
            </Box>
            <AchievementModal {...{open, setOpen, achievement}} isNew={true}/>
        </>

    );
};

export default Notifications;
