import React, {useContext, useEffect, useState} from 'react';
import {Box, Button, Container, Stack, Tab, Typography} from '@mui/material';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import SubscriptionCard from './Card';
import {useQuery} from '@apollo/client';
import {GET_SUBSCRIPTIONS} from 'graphql/subscription/getSubscriptions';
import CircularLoading from '../loading/CircularLoading';
import {useLocation, useNavigate} from 'react-router-dom';
import {ROUTES} from 'constants/routes';
import {TabsContext} from 'context/tabsContext';

const Subscription = ({linkWithTab}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const {saveTab} = useContext(TabsContext);
    const [value, setValue] = useState('');
    const isLanding = location.pathname === ROUTES.LANDING;
    const {data, loading, error} = useQuery(GET_SUBSCRIPTIONS, {
        variables: isLanding ? {filter: {isFavorite: {value: true}}} : {}
    });
    const subscriptions = data?.subscriptions;
    const TABS = subscriptions?.map((subscription) => ({
        title: subscription?.title,
        value: subscription?.id,
        component: <SubscriptionCard subscription={subscription} />
    }));

    useEffect(() => {
        if (!!subscriptions?.length) setValue(subscriptions?.[0]?.id);
    }, [subscriptions]);

    useEffect(() => {
        if (!!subscriptions?.length && linkWithTab) saveTab(value);
    }, [value]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            {((isLanding && !loading && subscriptions?.length !== 0) || !isLanding) && (
                <Container>
                    <Typography
                        variant={isLanding ? 'h3' : 'h2'}
                        sx={{textAlign: isLanding ? {xs: 'center', lg: 'left'} : 'left', mb: 7}}>
                        Подписка
                    </Typography>
                    <CircularLoading loading={loading || error}>
                        {subscriptions?.length !== 0 ? (
                            value && (
                                <TabContext value={value}>
                                    <Box mb={3}>
                                        <TabList
                                            sx={{overflow: 'auto'}}
                                            onChange={(event, newValue) => handleChange(event, newValue)}>
                                            {TABS?.map((tab) => (
                                                <Tab key={tab.value} label={tab.title} value={tab.value} />
                                            ))}
                                        </TabList>
                                    </Box>
                                    {TABS?.map((tab) => (
                                        <TabPanel key={tab.value} value={tab.value}>
                                            {tab.component}
                                        </TabPanel>
                                    ))}
                                </TabContext>
                            )
                        ) : (
                            <Typography sx={{textAlign: 'center', textTransform: 'uppercase'}}>
                                Скоро здесь ты сможешь оформить подписку
                            </Typography>
                        )}
                    </CircularLoading>
                    {isLanding && (
                        <Stack mx={'auto'} my={4} alignItems={'center'}>
                            <Button variant={'contained'} size={'large'} onClick={() => navigate(ROUTES.SUBSCRIPTION)}>
                                Все подписки
                            </Button>
                        </Stack>
                    )}
                </Container>
            )}
        </>
    );
};

export default Subscription;
