import {gql} from '@apollo/client';

export const GET_MEDIA_CENTERS = gql`
    query getMediaCenters {
        mediaCenters {
            id
            name
            address
            latitude
            longitude
        }
    }
`;
