import * as yup from 'yup';
import ERROR_MESSAGE from 'constants/errorMessage';

const REG_VALIDATION = yup.object().shape({
    firstName: yup.string().required(ERROR_MESSAGE.REQUIRED_EMPTY),
    lastName: yup.string().required(ERROR_MESSAGE.REQUIRED_EMPTY),
    phoneNumber: yup.string().min(11, ERROR_MESSAGE.REQUIRED_EMPTY).required(ERROR_MESSAGE.REQUIRED_EMPTY),
    middleName: yup.string(),
    email: yup.string().email(ERROR_MESSAGE.EMAIL).required(ERROR_MESSAGE.REQUIRED_EMPTY),
    password: yup.string().required(ERROR_MESSAGE.REQUIRED_EMPTY).min(6, 'Минимальное количество символов - 6'),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Пароли не совпадают')
        .required(ERROR_MESSAGE.REQUIRED_EMPTY)
});

const AUTH_VALIDATION = yup.object().shape({
    email: yup.string().email(ERROR_MESSAGE.EMAIL).required(ERROR_MESSAGE.REQUIRED_EMPTY),
    password: yup.string().required(ERROR_MESSAGE.REQUIRED_EMPTY)
});
const RESET_MODAL_VALIDATION = yup.object().shape({
    email: yup.string().email(ERROR_MESSAGE.EMAIL).required(ERROR_MESSAGE.REQUIRED_EMPTY)
});
const RESET_PASSWORD_VALIDATION = yup.object().shape({
    newPassword: yup.string().required(ERROR_MESSAGE.REQUIRED_EMPTY).min(6, 'Минимальное количество символов - 6'),
    againPassword: yup
        .string()
        .required(ERROR_MESSAGE.REQUIRED_EMPTY)
        .oneOf([yup.ref('newPassword'), null], 'Пароли не совпадают')
});

export {REG_VALIDATION, AUTH_VALIDATION, RESET_MODAL_VALIDATION, RESET_PASSWORD_VALIDATION};
