import React, {useEffect, useState} from 'react';
import {Box, Button, Grid, Link, Stack, Tooltip, Typography, useMediaQuery} from '@mui/material';
import InputWithLabel, {INPUT_TYPE} from '../inputs/InputWithLabel';
import {useForm} from 'react-hook-form';
import {formatCurrency, formatNumber} from 'helpers/formaters';
import CustomDivider from '../CustomDivider';
import {useNavigate} from 'react-router-dom';
import {ROUTES} from 'constants/routes';
import CustomModal from "components/modal/CustomModal";

const SubscriptionCard = ({subscription}) => {
    const [openInfo, setOpenInfo] = useState(false);
    const isLg = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const navigate = useNavigate();
    const form = useForm();
    const {control, watch, setValue} = form;
    const watchPeriod = watch('period');

    const periods = subscription?.subscriptionPeriods;
    const options = periods?.map((item) => ({
        value: item.id,
        label: `${formatNumber(item.duration, ['месяц', 'месяца', 'месяцев'])} = ${formatCurrency(item?.cost)}₽`
    }));
    const selectedOption = options?.find((item) => item?.value === watchPeriod);
    const selectedPeriod = periods?.find((item) => item?.id === selectedOption?.value);
    const benefit = selectedPeriod?.benefit;
    const detailDescription = subscription?.detailDescription;

    useEffect(() => {
        setValue('period', options[0].value);
    }, [periods]);

    return (
        <Grid
            container
            justifyContent={'space-between'}
            sx={{
                px: {xs: 3, md: 7, lg: 12},
                py: 6,
                border: (theme) => '3px solid' + theme.palette.primary.medium,
                borderRadius: '25px'
            }}>
            <Grid item xs={12} lg={5} mb={{xs: 3, lg: 0}}>
                <Box height={'100%'}>
                    <Stack spacing={2} mb={4.5}>
                        <Typography color={'primary.main'} sx={{textTransform: 'uppercase'}}>
                            Подписка
                        </Typography>
                        <Typography variant={'h4'}>{subscription?.title}</Typography>
                    </Stack>
                    <InputWithLabel
                        type={INPUT_TYPE.RADIO}
                        control={control}
                        name={'period'}
                        options={options}
                        sx={{mb: 1}}
                    />
                </Box>
            </Grid>
            {isLg && (
                <Grid item md={'auto'}>
                    <CustomDivider orientation={'vertical'} sx={{height: '100%'}} />
                </Grid>
            )}
            <Grid item xs={12} lg={5}>
                <Stack justifyContent={'space-between'} height={'100%'} spacing={3}>
                    <Stack spacing={2} lg={3}>
                        <Typography variant={'p2'} sx={{textTransform: 'uppercase', color: 'system.600'}}>
                            Описание
                        </Typography>
                        <Typography
                            variant={'p2'}
                            color={'systemShift.800'}
                            sx={{'& p': {m: 0}}}
                            dangerouslySetInnerHTML={{__html: subscription?.description}}
                        />
                        {detailDescription && <Link
                            onClick={() => setOpenInfo(true)}
                            color={'primary'}
                            variant={'p4'}
                            sx={{textAlign: 'end'}}>
                            Подробная информация
                        </Link>}
                    </Stack>
                    <Stack spacing={3} sx={{textTransform: 'uppercase'}}>
                        <Stack spacing={1}>
                            <Typography color={'system.600'} variant={'p2'}>
                                Срок:
                            </Typography>
                            <Typography variant={'p2'} minHeight={20}>
                                {selectedOption?.label || ''}
                            </Typography>
                        </Stack>
                        {!!selectedPeriod?.trialPeriodCost && (
                            <Stack spacing={1}>
                                <Typography color={'system.600'} variant={'p2'}>
                                    Стоимость пробного периода:
                                </Typography>
                                <Tooltip
                                    title={
                                        selectedPeriod?.currentUserHasTrialCost ? '' : 'Пробный период был использован'
                                    }
                                    placement={'bottom-start'}>
                                    <Typography
                                        variant={'p2'}
                                        sx={{
                                            textDecoration: selectedPeriod?.currentUserHasTrialCost
                                                ? 'none'
                                                : 'line-through',
                                            cursor: 'pointer'
                                        }}>
                                        {formatCurrency(selectedPeriod?.trialPeriodCost)}₽
                                    </Typography>
                                </Tooltip>
                            </Stack>
                        )}
                        <Grid
                            container
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            rowSpacing={{xs: 2, sm: 0}}>
                            <Grid item xs={12} sm={5}>
                                {benefit && (
                                    <Typography color={'primaryShift.main'}>
                                        Выгода {benefit}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6.5}>
                                <Button
                                    variant={'contained'}
                                    fullWidth={true}
                                    onClick={() => navigate(ROUTES.CHECKOUT + '/' + selectedPeriod?.id)}>
                                    Оформить подписку
                                </Button>
                            </Grid>
                        </Grid>
                    </Stack>
                </Stack>
            </Grid>
            <CustomModal
                open={openInfo}
                onClose={() => setOpenInfo(false)}
                title={'Подробная информация'}
                content={
                    <>
                        <Typography
                            color={'systemShift.700'}
                            dangerouslySetInnerHTML={{
                                __html: detailDescription
                            }}
                        />
                    </>
                }
                actionText={'Хорошо'}
                handleClick={() => setOpenInfo(false)}
            />
        </Grid>
    );
};

export default SubscriptionCard;
