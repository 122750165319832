import React, {useContext} from 'react';
import {Box, Stack, Typography, useMediaQuery} from '@mui/material';
import background from './background.svg';
import settingsContext from 'context/settingsContext';
import {THEMES} from 'constants/themes';
import {DotIcon} from 'assets/icons';
import LogoWhite from 'assets/images/logo-black.png';
import LogoBlack from 'assets/images/logo-white.png';

const Error = () => {
    const {settings} = useContext(settingsContext);
    const isLg = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const bgBottom = isLg ? '90px' : '10px';

    return (
        <Box
            display={'flex'}
            width={'100%'}
            maxHeight={'100%'}
            flexDirection={'column'}
            minHeight={'100vh'}
            justifyContent={'center'}>
            <Stack
                justifyContent={'space-around'}
                alignItems={'center'}
                width={'100%'}
                height={{xs: 300, md: 600, xl: 700}}
                sx={{
                    backgroundImage: {xs: 'none', sm: `url(${background})`},
                    backgroundPosition: `right 0 bottom ${bgBottom}`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '27% 87%'
                }}>
                <img
                    height={isLg ? 90 : 50}
                    src={settings.theme === THEMES.DARK ? LogoBlack : LogoWhite}
                    alt={'logo'}
                />
                <Stack spacing={2} px={{xs: 2, sm: 4}} py={4}>
                    <Typography
                        sx={{
                            fontSize: {xs: 20, md: 50, xl: 64},
                            lineHeight: '87px',
                            fontWeight: 700,
                            textAlign: {xs: 'center', sm: 'start'}
                        }}
                        mb={{xs: 2, lg: 4}}>
                        Не удалось получить доступ к сайту
                    </Typography>
                    <Box>
                        <Typography
                            variant={'h3'}
                            textTransform={'none'}
                            fontWeight={500}
                            color={'systemShift.700'}
                            mb={{xs: 2, lg: 3}}
                            sx={{
                                fontSize: {xs: 18, md: 30, lg: 40},
                                lineHeight: {xs: '20px', md: '24px'},
                                textAlign: {xs: 'center', sm: 'start'}
                            }}>
                            Вы можете попробовать следующее:
                        </Typography>
                    </Box>
                    {[
                        {text: 'Перейти к главной странице сайта'},
                        {text: 'Проверить правильность введенного адреса'},
                        {text: 'Вернуться туда, откуда пришли ', span: '(Нажать кнопку «Назад» в своем браузере)'}
                    ].map((item, index) => (
                        <Stack
                            direction={'row'}
                            alignItems={'center'}
                            spacing={2}
                            key={index}
                            color={'systemShift.700'}>
                            <Box>
                                <DotIcon />
                            </Box>
                            <Typography
                                variant={'h4'}
                                textTransform={'none'}
                                fontWeight={400}
                                sx={{
                                    fontSize: {xs: 12, md: 26, lg: 32},
                                    lineHeight: {xs: '20px', md: '40px'},
                                    span: {fontStyle: 'italic'}
                                }}>
                                {item.text}
                                {item?.span && <span>{item?.span}</span>}
                            </Typography>
                        </Stack>
                    ))}
                </Stack>
            </Stack>
        </Box>
    );
};

export default Error;
