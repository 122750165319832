import React from 'react';
import {Typography} from '@mui/material';
import checkMark from 'assets/icons/marker-check-mark.svg';

const Text = ({item, customText}) => {
    const content = customText || item?.content;
    return (
        <Typography
            sx={(theme) => ({
                '& ul': {
                    listStyleImage: `url(${checkMark})`,
                    '& li::marker': {color: 'primaryShift.main'}
                },
                '& li': {pt: 2},
                '& p': {lineHeight: 1.7},
                '& *': {my: item?.type === 'SLOT' ? 0 : undefined},
                '& a': {
                    color: theme.palette.primary.main,
                    '&:hover': {
                        color: theme.palette.primary.active
                    }
                }
            })}
            dangerouslySetInnerHTML={{
                __html: content
                    ?.replaceAll('color:#000000;', '')
                    .replaceAll('<ul>', '<ul style="list-style-image: none">')
                    .replaceAll(
                        '<ul style="list-style-type:circle;">',
                        '<ul style="list-style-type:circle; list-style-image: none">'
                    )
            }}
        />
    );
};
export default Text;
