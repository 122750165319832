import {ApolloClient, ApolloLink, from, InMemoryCache, Observable} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import {createUploadLink} from 'apollo-upload-client';
import cookies from 'js-cookie';
import {removeAuthCookies, setAuthCookies} from 'helpers/cookiesHelper';
import {ROUTES} from 'constants/routes';
import React from 'react';
import ERROR_TYPE from 'constants/errorType';

const graphqlLink = process.env.REACT_APP_API_URL + '/graphql';

const httpLink = createUploadLink({
    uri: graphqlLink,
    fetchOptions: {
        credentials: 'include'
    },
    credentials: 'include'
});

const authMiddleware = new ApolloLink((operation, forward) => {
    const token = cookies.get('accessToken');
    operation.setContext({headers: {authorization: token ? 'JWT ' + token : null}});

    return forward(operation);
});

let isRefreshing = false;
let failedRequestsQueue = [];

const processQueue = async (error, token = null) => {
    failedRequestsQueue.forEach((prom) => (error ? prom.reject(error) : prom.resolve(token)));
    failedRequestsQueue = [];
    isRefreshing = false;
};

const refreshAccessToken = async (execType) => {
    const r = await fetch(graphqlLink, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'JWT ' + cookies.get('accessToken')
        },
        body: JSON.stringify({
            query: `mutation refreshTokens {refreshTokens(refreshToken: "${cookies.get('refreshToken')}") {
                                            accessToken
                                            refreshToken
                                        }}`
        })
    });
    return await r.json();
};

const errorLink = onError(({graphQLErrors, networkError, operation, forward}) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({extensions, message, locations, path}) => {
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
            if (
                extensions?.exception?.response?.type === ERROR_TYPE.USER_IS_BLOCKED &&
                window.location.pathname !== ROUTES.AUTH
            ) {
                removeAuthCookies(['accessToken']);
            }
        });
    }
    if (networkError) console.log(`[Network error]: ${networkError}`);
    if (
        graphQLErrors.find(({message}) => message === 'Unauthorized') &&
        operation.operationName !== 'loginUser' &&
        operation.operationName !== 'me'
    ) {
        return new Observable(async (observer) => {
            //Кладем запрос в очередь отклоненных запросов, там он будет ждать решения по обновлению токена
            new Promise((resolve, reject) => {
                failedRequestsQueue.push({resolve, reject});
            })
                .then((accessToken) => {
                    //Если все ок, то идем дальше, пуская вперед остальные запросы;
                    const subscriber = {
                        next: observer.next.bind(observer),
                        error: observer.error.bind(observer),
                        complete: observer.complete.bind(observer)
                    };
                    forward(operation).subscribe(subscriber);
                })
                .catch((e) => {
                    //Refresh-токен тоже просрочен, редирект на авторизацию произведет первый запрос в очереди отклоненных
                });
            //Если данный запрос первый в очереди отклоненных, то есть до него никто не поставил isRefreshing
            if (!isRefreshing) {
                isRefreshing = true;
                try {
                    //Идем вручную на рефреш токена, ибо клиент Apollo испорчен старым токеном до момента обновления
                    const data = await refreshAccessToken();

                    //Если токен не получилось обновить, идем на авторизацию
                    if (data.errors?.length) {
                        throw new Error('Error refreshing token');
                    }
                    //Если все ок, обновляем токен
                    const {
                        data: {
                            refreshTokens: {accessToken, refreshToken}
                        }
                    } = data;
                    const _cookies = [
                        {
                            name: 'accessToken',
                            value: accessToken
                        },
                        {
                            name: 'refreshToken',
                            value: refreshToken
                        }
                    ];
                    setAuthCookies(_cookies);
                    //Запускаем очередь отклоненных запросов с новым токеном
                    await processQueue(null, accessToken);
                } catch (e) {
                    await processQueue(e, null);
                    //Аналогично ошибкам GQL, если не достучались до сервера вообще, идем на авторизацию

                    removeAuthCookies(['accessToken', 'refreshToken']);
                    window.location.href = ROUTES.AUTH + `?redirectUrl=${encodeURIComponent(location.href)}`;
                }
            }
        });
    }
});

export const client = new ApolloClient({
    link: from([errorLink, authMiddleware, httpLink]),
    cache: new InMemoryCache()
});
