import React, {useMemo} from 'react';
import {Box, Button, CardMedia, Grid, Link, Stack, styled, Tooltip, Typography, useMediaQuery} from '@mui/material';
import CustomDivider from '../CustomDivider';
import {CARD_TYPE, VARIANT_BUTTON} from 'constants/cards';
import {checkIsPreview} from 'helpers';
import Icons from './Icons';
import {EVENT_NOTE_TYPE, EVENT_TYPE, FORMAT_TYPE} from 'constants/type';
import {FILE_TYPE_ENUM} from "constants/global";

const CoursesAndEventsCard = ({card, isLgPreview}) => {
    const isPreview = checkIsPreview();
    const isLg = isPreview ? isLgPreview : useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const event = card?.event || card?.eventNote;
    const coverFile = event?.coverFile  || event?.files?.find((file)=>file?.type===FILE_TYPE_ENUM.COVER)?.file
    const buttons = card?.buttons?.filter((button) => !button?.isHidden);
    const icons = card?.icons?.filter((icon) => !icon?.isHidden && (!!icon?.description || !!icon?.iconImage));
    const tags = useMemo(() => {
        let tagState = [];
        !card?.eventTypeIsHidden &&
            tagState.push({
                title: card?.type === CARD_TYPE.EVENT ? EVENT_TYPE[event?.type] : EVENT_NOTE_TYPE[event?.type],
                variant: VARIANT_BUTTON[card?.eventTypeTheme]
            });
        card?.type === CARD_TYPE.EVENT &&
            !card?.MTTypeIsHidden &&
            event?.MTType &&
            tagState.push({
                title: event?.MTType,
                variant: VARIANT_BUTTON[card?.MTTypeTheme]
            });
        !card?.formatIsHidden &&
            event?.format?.map((format) =>
                tagState.push({
                    title: FORMAT_TYPE[format],
                    variant: VARIANT_BUTTON[card?.formatTheme]
                })
            );

        !card?.tagIsHidden && !!card?.tag && tagState.push({title: card?.tag, variant: VARIANT_BUTTON[card?.tagTheme]});
        return tagState;
    }, [card]);

    return (
        <StyledCard direction={'row'} component={Link} href={card.link} target='_blank' spacing={6} isLg={isLg}>
            {isLg && !!coverFile && (
                <Stack justifyContent={'start'}>
                    <CardMedia
                        component='img'
                        image={coverFile?.url}
                        alt={coverFile?.name}
                        sx={{width: isLg ? 250 : 150, height: 'auto'}}
                    />
                </Stack>
            )}
            <Stack width={'100%'} justifyContent={'space-between'} spacing={isLg ? 4 : 2}>
                <Stack direction={'row'} spacing={2} height={'100%'}>
                    <Stack width={'100%'}>
                        {((!isLg && !!coverFile) || !!tags?.length) && (
                            <Stack direction={'row'} spacing={1} justifyContent={'space-between'} mb={isLg ? 3 : 2}>
                                {!isLg && !!coverFile && (
                                    <CardMedia
                                        component='img'
                                        image={coverFile?.url}
                                        alt={coverFile?.name}
                                        sx={{width: isLg ? 250 : 140, height: 'auto'}}
                                    />
                                )}
                                {!!tags?.length &&
                                    (isLg ? (
                                        <Grid container columnSpacing={2.5} rowSpacing={1.5}>
                                            {tags?.map((tag, index) => (
                                                <Grid item xs={'auto'} key={index}>
                                                    <Button size={'small'} variant={tag?.variant}>
                                                        {tag?.title}
                                                    </Button>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    ) : (
                                        <Stack direction={!!coverFile ? 'column' : 'row'} spacing={1.5}>
                                            {tags?.map(
                                                (tag, index) =>
                                                    index < 2 && (
                                                        <Tooltip
                                                            key={index}
                                                            title={tag?.title.length > 15 ? tag?.title : ''}>
                                                            <Button
                                                                size={'small'}
                                                                variant={tag?.variant}
                                                                sx={{
                                                                    px: !!coverFile ? 0.5 : 'auto',
                                                                    fontSize: 12
                                                                }}>
                                                                {tag?.title.length > 15
                                                                    ? tag?.title.slice(0, 15) + '...'
                                                                    : tag?.title}
                                                            </Button>
                                                        </Tooltip>
                                                    )
                                            )}
                                        </Stack>
                                    ))}
                            </Stack>
                        )}
                        <Stack spacing={2}>
                            <Typography variant={'h4'}>{event?.title}</Typography>
                            <Typography variant={'p2'}>{event?.description}</Typography>
                        </Stack>
                    </Stack>
                    {isLg && !!icons?.length && (
                        <>
                            <CustomDivider orientation={'vertical'} />
                            <Icons icons={icons} isLg={isLg} />
                        </>
                    )}
                </Stack>
                {!!buttons?.length && (
                    <Grid
                        container
                        rowSpacing={isLg ? 0 : 2}
                        justifyContent={!!coverFile ? 'space-between' : 'end'}>
                        {buttons?.map((button, index) => (
                            <Grid
                                item
                                key={button?.id}
                                xs={isLg ? (!!coverFile ? 6 : 4) : 12}
                                pl={isLg && index > 0 ? 2 : 0}>
                                <Tooltip title={button?.title.length > 25 ? button?.title : ''}>
                                    <Button
                                        variant={VARIANT_BUTTON[button?.colorTheme]}
                                        href={button?.link || undefined}
                                        target='_blank'
                                        fullWidth>
                                        {button?.title.length > 25 ? button?.title.slice(0, 25) + '...' : button?.title}
                                    </Button>
                                </Tooltip>
                            </Grid>
                        ))}
                    </Grid>
                )}
                {!isLg && !buttons?.length && !!icons?.length && <CustomDivider />}
                {!isLg && !!icons?.length && (
                    <Box mt={1} sx={{display: 'flex', justifyContent: 'center'}}>
                        <Icons icons={icons} />
                    </Box>
                )}
            </Stack>
        </StyledCard>
    );
};

const StyledCard = styled(Stack, {shouldForwardProp: (prop) => prop !== 'isLg'})(({theme, isLg}) => ({
    width: '100%',
    maxWidth: isLg ? 1200 : 400,
    padding: isLg ? theme.spacing(4) : theme.spacing(2.5),
    textTransform: 'none',
    border: '3px solid',
    borderRadius: '25px',
    borderColor: theme.palette.primary.medium,
    '&:hover': {
        borderColor: theme.palette.primary.main
    }
}));

export default CoursesAndEventsCard;
