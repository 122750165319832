import * as React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import App from './App';
import 'assets/styles/styles.css';
import {SettingsProvider} from 'context/settingsContext';
import {client} from 'config/apolloConfig';
import {ApolloProvider} from '@apollo/client';

ReactDOM.render(
    <SettingsProvider>
        <ApolloProvider client={client}>
            <Router>
                <App />
            </Router>
        </ApolloProvider>
    </SettingsProvider>,
    document.querySelector('#root')
);
