export const ROUTES = {
    LANDING: '/',
    AUTH: '/auth',
    REG: '/reg',
    PROFILE: '/profile',
    REG_SUCCESS: '/reg/success',
    ABOUT: '/about',
    COURSES_AND_EVENTS: '/courses-and-events',
    BLOG: '/blog',
    CALENDAR: '/calendar',
    CHECKOUT: '/checkout',
    TRAJECTORY: '/trajectory',
    EVENTS: '/events',
    SUBSCRIPTION: '/subscription',
    FORMS_FESTIVAL_REG: '/forms/congress-registration',
    FORMS_INTENSIVE: '/forms/intensive',
    FORMS_VOLUNTEER: '/forms/volunteer',
    FORMS_OFFLINE_INTENSIVE: '/forms/offline-intensive',
    FORMS_CREATE_MEDIA_CENTER: '/forms/create-mediacenter',
    FORMS_JOIN_MEDIA_CENTER: '/forms/join-mediacenter',
    FORMS_UPDATE_JOIN_MEDIA_CENTER: '/forms/update-join-mediacenter',
    FORMS_MEDIA_CENTER_SUPERVISOR: '/forms/mediacenter-supervisor',
    EMAIL_CONFIRM: '/email/confirm',
    EMAIL_SUCCESS: '/email/success',
    AUTH_CALLBACK: '/auth/callback',
    SERVICES: 'forms/services',
    CHATS: '/chats',
    ORGANIZATION_INFORMATION: '/information-about-the-educational-organization'
};
