import {gql} from '@apollo/client';

export const GET_ORGANIZATION_INFO = gql`
    query getOrganizationInfo {
        landingInformation(type: ABOUT_INFO) {
            id
            content
        }
    }
`;
