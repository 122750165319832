import React, {createContext, useContext, useEffect} from 'react';
import {useQuery} from '@apollo/client';
import {ME} from 'graphql/auth/query/me';
import {useLocation, useNavigate} from 'react-router-dom';
import {ROUTES} from 'constants/routes';
import {PROFILE_TAB} from './tabsContext';
import {TOAST_TEXT, TOAST_TYPE, ToastContext} from './toastContext';
import posthog from 'posthog-js';

export const AuthContext = createContext({
    captureGoal: () => {}
});

export const AuthProvider = ({children}) => {
    const {addToast} = useContext(ToastContext);
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const {data, refetch, error, loading} = useQuery(ME);
    const me = data?.me;
    const lastSubscriptionPeriod = data?.me?.userSubscriptionPeriods?.[0];

    useEffect(() => {
        posthog.init(process.env.REACT_APP_POSTHOG_TOKEN, {
            api_host: process.env.REACT_APP_POSTHOG_API_HOST,
            autocapture: {
                event_allowlist: ['click', 'submit'] // DOM events from this list ['click', 'change', 'submit']
            }
        });
        if (me?.id) {
            posthog.identify(me.id);
        }
    }, [me?.id]);

    useEffect(() => {
        if (!!me && pathname.includes('/auth')) {
            navigate(`${ROUTES.PROFILE}?tab=${PROFILE_TAB.PERSONAL}`);
        }
        if (
            !!me &&
            !me?.email?.isConfirmed &&
            ((pathname.includes('/events') && !pathname.includes('/events/preview')) || pathname.includes('trajectory'))
        ) {
            navigate(`${ROUTES.PROFILE}?tab=${PROFILE_TAB.SAFETY}`);
            addToast({
                text: TOAST_TEXT.EMAIL_IS_NOT_CONFIRMED,
                type: TOAST_TYPE.ERROR
            });
        } else if (
            !!me &&
            pathname.includes('/forms') &&
            !pathname.includes('/services') &&
            (!me?.email?.isConfirmed || !me?.birthDate || !me?.city?.id)
        ) {
            navigate(`${ROUTES.PROFILE}?tab=${PROFILE_TAB.SAFETY}`);
            addToast({
                text: 'Для подачи заявки необходимо указать дату рождения, город проживания и подтвердить e-mail. Запросить ссылку на подтверждение e-mail можно в личном кабинете на вкладке "Безопасность".',
                type: TOAST_TYPE.ERROR
            });
        }
    }, [me, pathname]);

    useEffect(() => {
        if (!me && !loading && pathname.includes('chats'))
            navigate(ROUTES.AUTH + `?redirectUrl=${encodeURIComponent(location.href)}`);
    }, [loading]);

    const captureGoal = (eventName, properties, checkMe = true) => {
        if ((checkMe && data?.me?.id) || !checkMe) {
            posthog.capture(eventName, {
                ...properties,
                userId: data?.me?.id
            });
        }
    };
    return (
        <AuthContext.Provider value={{data, refetch, error, loading, lastSubscriptionPeriod, captureGoal}}>
            {children}
        </AuthContext.Provider>
    );
};
