import {Box, Link as MuiLink, Stack, Typography} from '@mui/material';
import {Link, useNavigate} from 'react-router-dom';
import {ROUTES} from 'constants/routes';
import {THEMES} from 'constants/themes';
import LogoBlack from 'assets/images/logo-white.png';
import LogoWhite from 'assets/images/logo-black.png';
import NavList from 'layouts/MainLayout/NavList';
import {NETWORK} from 'constants/network';
import moment from 'moment/moment';
import React, {useContext} from 'react';
import settingsContext from 'context/settingsContext';
import {saveAs} from 'file-saver';
import {COMMON_DATA} from 'layouts/MainLayout/Footer/index';

const MobileFooter = ({privacyPolicyFile, publicOfferFile}) => {
    const navigate = useNavigate();
    const {settings} = useContext(settingsContext);

    const ADDRESSES_MOBILE = [COMMON_DATA.phone, COMMON_DATA.email, COMMON_DATA.address];

    return (
        <Stack
            direction={'column'}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{mt: 6, height: 'auto', '& *': {color: 'system.600'}}}>
            <Box component={Link} to={ROUTES.LANDING} onClick={() => window.scrollTo(0, 0)}>
                <img src={settings.theme === THEMES.DARK ? LogoBlack : LogoWhite} height={35} alt={'logo'} />
            </Box>
            <Stack alignItems={'center'} spacing={1} sx={{my: 3, '& .MuiListItem-root': {paddingTop: 0}}}>
                <NavList />
            </Stack>
            <Stack
                direction={'row'}
                justifyContent={'center'}
                sx={{
                    width: '100%',
                    py: 3,
                    borderTop: (theme) => '1px solid' + theme.palette.systemShift['400'],
                    borderBottom: (theme) => '1px solid' + theme.palette.systemShift['400']
                }}>
                {NETWORK.map((item, index) => (
                    <MuiLink href={item.link} key={index} sx={{mx: 4, '& svg': {height: '15px'}}}>
                        {item.icon}
                    </MuiLink>
                ))}
            </Stack>
            <Stack alignItems={'center'} sx={{my: 4}} spacing={3}>
                {ADDRESSES_MOBILE.map((item, index) => (
                    <Typography key={index} noWrap>
                        {item}
                    </Typography>
                ))}
                {privacyPolicyFile && (
                    <Typography
                        noWrap
                        sx={{whiteSpace: 'pre-wrap', cursor: 'pointer'}}
                        onClick={() => saveAs(privacyPolicyFile, 'privacy-policy.pdf')}>
                        Политика конфиденциальности
                    </Typography>
                )}
                {publicOfferFile && (
                    <Typography
                        noWrap
                        sx={{whiteSpace: 'pre-wrap', cursor: 'pointer'}}
                        onClick={() => saveAs(publicOfferFile, 'oferta.pdf')}>
                        Условия публичной оферты
                    </Typography>
                )}
                <Typography
                    noWrap
                    sx={{whiteSpace: 'pre-wrap', cursor: 'pointer'}}
                    onClick={() => window.open(COMMON_DATA.reportsLink)}>
                    Отчеты
                </Typography>
                <Typography
                    variant={'p4'}
                    noWrap
                    sx={{whiteSpace: 'pre-wrap', cursor: 'pointer'}}
                    onClick={() => navigate(ROUTES.ORGANIZATION_INFORMATION)}>
                    Сведения об образовательной организации
                </Typography>
                <Typography noWrap>(с) 2021-{moment().format('YYYY')} АНО «Лаборатория медиа»</Typography>
                <Typography mt={9} px={4} textAlign={'center'} variant={'p4'}>
                    Образовательные услуги оказываются Автономной&nbsp;некоммерческой&nbsp;организацией
                    «Лаборатория&nbsp;медиа» на основании Лицензии №&nbsp;Л035-01298-77/00665244
                    от&nbsp;20&nbsp;июля&nbsp;2023. Образовательные услуги оказываются в соответствии с Федеральным
                    законом от&nbsp;04.05.2011 №&nbsp;99-ФЗ «О лицензировании отдельных видов деятельности».
                </Typography>
            </Stack>
        </Stack>
    );
};

export default MobileFooter;
