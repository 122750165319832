import cookies from 'js-cookie';
import moment from 'moment';

export const setAuthCookies = (_cookies = []) => {
    const currentHostname = window.location.hostname;
    for (const cookie of _cookies) {
        cookies.set(cookie.name, cookie.value, {
            expires: moment().add(30, 'days').toDate(),
            domain: currentHostname === 'localhost' ? undefined : '.' + currentHostname,
            ...cookie.options
        });
    }
};

export const removeAuthCookies = (_cookies = []) => {
    const currentHostname = window.location.hostname;
    for (const cookie of _cookies) {
        cookies.remove(cookie, {
            path: '/',
            domain: currentHostname === 'localhost' ? undefined : '.' + currentHostname,
            ...cookie.options
        });
    }
};
