import React, {createContext, useContext, useEffect, useRef, useState} from 'react';
import {AuthContext} from './authContext';
import {Tinode} from 'tinode-sdk';
import {CHAT_TYPE} from 'pages/Chats/components/Contacts';

const API_KEY = 'AQEAAAABAAD_rAp4DJh05a1HAwFT3A6K';
export const TinodeContext = createContext({});

export const TinodeProvider = ({children}) => {
    const {data} = useContext(AuthContext);
    const secret = data?.me?.tinodeUserSecret;
    const tinode = useRef(new Tinode({host: process.env.REACT_APP_TINODE_HOST, apiKey: API_KEY, secure: true}));
    const topic = useRef();
    const me = useRef();
    const [topicName, setTopicName] = useState('');
    const [contacts, setContacts] = useState([]);
    const [contactsLoading, setContactsLoading] = useState(true);
    const [messages, setMessages] = useState([]);
    const [isNewMessages, setIsNewMessages] = useState({
        [CHAT_TYPE.ALL]: false,
        [CHAT_TYPE.GLOBAL]: false,
        [CHAT_TYPE.EVENT]: false
    });
    const [topicUsers, setTopicUsers] = useState([]);

    useEffect(() => {
        if (!!secret) {
            tinode.current?.connect();
            tinode.current.onConnect = () => tinode.current?.login('basic', secret);
        }
        return () => {
            topic.current?.leave('me', false);
            tinode.current?.disconnect();
        };
    }, [secret]);

    useEffect(() => {
        me.current = tinode.current?.getMeTopic();
        tinode.current.onLogin = () => subscribe();
        tinode.current.onDataMessage = (data) => {
            if (data?.topic === topicName)
                setMessages((prevState) => [...prevState, {...data, user: topic.current?.userDesc(data.from)}]);
        };
        me.current.onContactUpdate = (type, contact) => {
            if (type === 'msg' && !!contact?.unread) {
                isNewMessagesUpdate();
                if (contact?.name !== topicName) {
                    contactUpdate();
                }
            } else if (type === 'read') {
                setIsNewMessages({[CHAT_TYPE.ALL]: false, [CHAT_TYPE.GLOBAL]: false, [CHAT_TYPE.EVENT]: false});
                isNewMessagesUpdate();
            }
        };
    }, [secret, topicName, topic.current, topic.current?.unread]);

    const subscribe = () => {
        let getQuery = me.current?.startMetaQuery().withLaterDesc().withLaterSub();
        me.current?.subscribe(getQuery.build());
        topic.current = tinode.current?.getTopic(topicName);
        setTimeout(() => {
            isNewMessagesUpdate();
            setContactsLoading(false);
            contactUpdate();
            if (!!topicName) {
                const unread = topic.current?.unread > 24 ? topic.current?.unread + 1 : 24;
                let getQuery = topic.current?.startMetaQuery().withLaterDesc().withLaterSub().withLaterData(unread);
                topic.current?.subscribe(getQuery.build());
                setTimeout(() => {
                    topic.current?.subscribers((data) => setTopicUsers((prevState) => [...prevState, data]));
                }, 500);
            }
        }, 500);
    };

    const contactUpdate = () => {
        setContacts([]);
        me.current?.contacts((data) => setContacts((prevState) => [...prevState, data]));
    };
    const messageUpdate = (name) => {
        topic.current = tinode.current?.getTopic(name);
        const unread = topic.current?.unread > 24 ? topic.current?.unread + 1 : 24;
        let getQuery = topic.current?.startMetaQuery().withLaterDesc().withLaterSub().withLaterData(unread);
        topic.current?.subscribe(getQuery.build());
        setTopicUsers([]);
        setMessages([]);
        setTimeout(() => {
            topic.current?.subscribers((data) => setTopicUsers((prevState) => [...prevState, data]));
        }, 500);
        topic.current?.messages((data) =>
            setMessages((prevState) => [...prevState, {...data, user: topic.current?.userDesc(data.from)}])
        );
    };
    const isNewMessagesUpdate = () =>
        me.current?.contacts((data) => {
            if (!!data?.unread) {
                data?.public?.tags?.type === CHAT_TYPE.EVENT
                    ? setIsNewMessages((prevState) => ({
                          [CHAT_TYPE.ALL]: true,
                          [CHAT_TYPE.GLOBAL]: prevState[CHAT_TYPE.GLOBAL],
                          [CHAT_TYPE.EVENT]: true
                      }))
                    : setIsNewMessages((prevState) => ({
                          [CHAT_TYPE.ALL]: true,
                          [CHAT_TYPE.GLOBAL]: true,
                          [CHAT_TYPE.EVENT]: prevState[CHAT_TYPE.EVENT]
                      }));
            }
        });

    const topicNameUpdate = (data) => setTopicName(data);

    return (
        <TinodeContext.Provider
            value={{
                tinode,
                topic,
                messages,
                messageUpdate,
                contacts,
                contactUpdate,
                isNewMessages,
                topicNameUpdate,
                contactsLoading,
                topicUsers
            }}>
            {children}
        </TinodeContext.Provider>
    );
};
