import React, {useState, useEffect, useContext, useRef} from 'react';
import {Box, FormControlLabel, Stack, styled, Switch, Tabs, Typography, useMediaQuery} from '@mui/material';
import {TabContext} from '@mui/lab';
import NotificationTab from './NotificationTab';
import NotificationTabPanel from './NotificationTabPanel';
import {NotificationContext} from 'context/notificationContext';
import {useUnseenCount} from '@novu/notification-center';
import {NOTIFICATIONS_TAB_TYPE, NOTIFICATIONS_TAB_TYPE_ENUM} from 'constants/notifications';

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const NotificationTabs = () => {
    const {unseenCount} = useUnseenCount();
    const [value, setValue] = useState(NOTIFICATIONS_TAB_TYPE_ENUM.ALL);
    const {getNotifications, notifications, setNotifications, onlyUnread, setOnlyUnread, initialized} =
        useContext(NotificationContext);
    const isLg = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    const prevUnseenCount = usePrevious(unseenCount);

    useEffect(async () => {
        const newNotifications = await getNotifications({tab: value, onlyUnread});
        if (!notifications?.length || onlyUnread) setNotifications(newNotifications);
        else if (!prevUnseenCount || prevUnseenCount < unseenCount) {
            const concatedNotifications = [];
            newNotifications.every(
                (newNotification) =>
                    notifications[0]['_id'] !== newNotification['_id'] && concatedNotifications.push(newNotification)
            );
            setNotifications([...concatedNotifications, ...notifications]);
        }
    }, [unseenCount]);

    useEffect(async () => {
        setNotifications(await getNotifications({tab: value, onlyUnread}));
    }, [onlyUnread, value, initialized]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeFilter = () => {
        setOnlyUnread(!onlyUnread);
    };

    return (
        <Box>
            <Stack
                justifyContent={'space-between'}
                alignItems={'center'}
                direction={'row'}
                flexWrap={'wrap'}
                mt={2}
                pb={2}
                px={3}
                sx={{borderBottom: 'solid 1px', borderColor: 'grey.400'}}>
                <Typography variant='h5'>Уведомления</Typography>
                {isLg && (
                    <Stack>
                        <FormControlLabel
                            checked={onlyUnread}
                            onChange={handleChangeFilter}
                            sx={{ml: 0}}
                            control={<ThemeSwitch color={'primary'} />}
                            labelPlacement={'start'}
                            label='Только непрочитанные'
                            componentsProps={{
                                typography: {
                                    sx: {
                                        fontSize: '14px !important',
                                        color: 'systemShift.500',
                                        mr: 2
                                    },
                                    noWrap: true
                                }
                            }}
                        />
                    </Stack>
                )}
            </Stack>
            {!isLg && (
                <FormControlLabel
                    checked={onlyUnread}
                    onChange={handleChangeFilter}
                    sx={{mt: 2, mx: 3}}
                    control={<ThemeSwitch color={'primary'} />}
                    labelPlacement={'start'}
                    label='Только непрочитанные'
                    componentsProps={{
                        typography: {
                            sx: {
                                fontSize: '14px !important',
                                color: 'systemShift.500',
                                mr: 2
                            },
                            noWrap: true
                        }
                    }}
                />
            )}
            <TabContext value={value}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant={'scrollable'}
                    scrollButtons={false}
                    sx={{
                        minHeight: 0,
                        mx: 3,
                        pt: 3,
                        pb: 0,
                        '.MuiTabs-flexContainer': {
                            justifyContent: 'space-between',
                            gap: 'normal !important'
                        }
                    }}>
                    {Object.keys(NOTIFICATIONS_TAB_TYPE_ENUM).map((tab, i) => (
                        <NotificationTab key={i} value={tab} label={NOTIFICATIONS_TAB_TYPE[tab]} />
                    ))}
                </Tabs>
                <NotificationTabPanel value={value} />
            </TabContext>
        </Box>
    );
};

const ThemeSwitch = styled(Switch, {shouldForwardProp: (prop) => prop !== 'theme'})(({theme}) => ({
    padding: 8,
    marginRight: 20,
    marginLeft: -10,
    cursor: 'pointer',
    '& .MuiSwitch-switchBase': {
        background: 'none',
        '&.Mui-checked:hover': {background: 'none'},
        '&:hover': {background: 'none'},
        '&.Mui-checked': {
            '& + .MuiSwitch-track': {
                opacity: 1,
                background: 'none',
                border: '1px solid',
                borderColor: theme.palette.primary.main
            },
            '.MuiSwitch-thumb': {
                backgroundColor: theme.palette.primary.main
            }
        }
    },
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        opacity: 1,
        background: 'none',
        border: '1px solid',
        borderColor: theme.palette.grey['500'],
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            top: '50%',
            transform: 'translateY(-50%)',
            opacity: 0.6,
            width: 16,
            height: 16
        }
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 22,
        height: 22,
        marginTop: -1,
        marginLeft: -1,
        backgroundColor: theme.palette.grey['500']
    }
}));

export default NotificationTabs;
