import {gql} from '@apollo/client';

export const GET_SUBSCRIPTIONS = gql`
    query getSubscriptions($filter: SubscriptionCompositeFilter) {
        subscriptions(filter: $filter) {
            id
            title
            description
            isFavorite
            detailDescription
            subscriptionPeriods {
                id
                duration
                cost
                trialPeriodCost
                currentUserHasTrialCost
                benefit
            }
        }
    }
`;
