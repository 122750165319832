export const formatNumber = (number, titles, onlyTitle) => {
    //titles example ['день', 'дня', 'дней']
    const cases = [2, 0, 1, 1, 1, 2];
    if (!number) number = 0;
    return (
        (onlyTitle ? '' : number + ' ') +
        titles[
            Math.abs(number) % 100 > 4 && Math.abs(number) % 100 < 20
                ? 2
                : cases[Math.abs(number) % 10 < 5 ? Math.abs(number) % 10 : 5]
        ]
    );
};

export const formatCurrency = (currency, comma = 2) => {
    if (!currency) currency = 0;
    return new Intl.NumberFormat('ru-RU', {
        currency: 'RUB',
        maximumFractionDigits: comma,
        minimumFractionDigits: comma
    }).format(currency);
};

export const formatPhone = (phoneNumberString = '') => {
    let cleaned = phoneNumberString.replace(/\D/g, '');
    let match = cleaned.match(/^(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/);
    return match ? ['+', match[1], ' (', match[2], ') ', match[3], '-', match[4], '-', match[5]].join('') : '–';
};
