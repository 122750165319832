import React from 'react';
import {List, ListItem, Typography} from '@mui/material';
import {Link, useLocation} from 'react-router-dom';
import {ROUTES} from 'constants/routes';

const AuthList = () => {
    const location = useLocation();
    const routes = [
        {title: 'Регистрация', to: ROUTES.REG},
        {title: 'Вход', to: ROUTES.AUTH}
    ];

    return (
        <List sx={{display: 'flex', justifyContent: 'space-between'}}>
            {routes.map((item, index) => (
                <ListItem
                    component={Link}
                    to={item.to}
                    onClick={() => window.scrollTo(0, 0)}
                    disableGutters
                    key={`list-item-${index}`}
                    sx={{mx: 1, '&:last-child': {mr: 0}}}>
                    <Typography
                        variant={'p2'}
                        fontWeight={700}
                        noWrap
                        color={location.pathname === item.to ? 'systemShift.900' : 'systemShift.600'}>
                        {item.title}
                    </Typography>
                </ListItem>
            ))}
        </List>
    );
};

export default AuthList;
