import {Checkbox, FormControlLabel} from '@mui/material';
import {Controller} from 'react-hook-form';
import React from 'react';

const CheckboxControl = ({name, control, label, sx, disabled, onChange, variant, defaultValue}) => {
    return (
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={({field}) => (
                <FormControlLabel
                    onChange={(e) => {
                        if (onChange) onChange(e);
                        field.onChange(e.target.checked);
                    }}
                    checked={!!field.value}
                    sx={{
                        color: (theme) =>
                            variant === 'success'
                                ? theme.palette.success.main
                                : variant === 'error'
                                ? theme.palette.error.main
                                : '',
                        ...sx
                    }}
                    control={<Checkbox />}
                    label={label}
                    disabled={disabled}
                />
            )}
        />
    );
};

export default CheckboxControl;
