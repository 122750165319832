import React, {useContext, useState} from 'react';
import {Stack, Typography} from '@mui/material';
import {TextFieldControl} from 'components/inputs';
import {useForm} from 'react-hook-form';
import background from 'pages/About/icons/background-card.svg';
import {useMutation} from '@apollo/client';
import {TELEGRAM_NEWS_NOTIFICATION} from 'graphql/about/telegramNewsNotification';
import {TOAST_TYPE, ToastContext} from 'context/toastContext';
import {LoadingButton} from '@mui/lab';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import ERROR_MESSAGE from 'constants/errorMessage';

const SubscribeForm = () => {
    const schema = yup.object().shape({
        email: yup.string().email(ERROR_MESSAGE.EMAIL).required(ERROR_MESSAGE.REQUIRED_EMPTY),
        firstName: yup.string().required(ERROR_MESSAGE.REQUIRED_EMPTY),
        lastName: yup.string().required(ERROR_MESSAGE.REQUIRED_EMPTY)
    });
    const form = useForm({resolver: yupResolver(schema)});
    const {control, handleSubmit} = form;

    const [telegramNewsNotification] = useMutation(TELEGRAM_NEWS_NOTIFICATION);
    const [sent, setSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const {addToast} = useContext(ToastContext);

    const onSubmit = (data) => {
        setLoading(true);
        telegramNewsNotification({
            variables: {
                input: {
                    email: data.email.toLowerCase(),
                    lastName: data.lastName,
                    firstName: data.firstName
                }
            }
        })
            .then(() => {
                setLoading(false);
                setSent(true);
            })
            .catch(() => addToast({type: TOAST_TYPE.ERROR}));
    };
    return (
        <Stack
            height={'100%'}
            borderRadius={'20px'}
            alignItems={'center'}
            p={{xs: 3, md: 6}}
            sx={{
                backgroundColor: {xs: 'transparent ', md: 'background.card'},
                backgroundImage: {xs: 'none', md: `url(${background}), url(${background})`},
                backgroundPosition: 'left -90px top -70px, right -20px top 20px',
                backgroundRepeat: 'no-repeat',
                border: (theme) => ({xs: '3px solid' + theme.palette.primary.medium, md: 'none'})
            }}>
            <Stack spacing={{xs: 3, md: 6}} maxWidth={1000}>
                <Typography variant={'p1'}>
                    Подпишись, чтобы получать приглашения на бесплатные вебинары, узнавать об актуальных курсах и
                    проектах Лаборатории медиа.
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack direction={{xs: 'column', md: 'row'}} spacing={{xs: 2, md: 5}} mb={{xs: 2, md: 5}}>
                        <TextFieldControl control={control} placeholder={'Фамилия'} name={'lastName'} />
                        <TextFieldControl control={control} placeholder={'Имя'} name={'firstName'} />
                        <TextFieldControl control={control} placeholder={'e-mail'} name={'email'} />
                    </Stack>
                    <Stack direction={{xs: 'column', md: 'row'}} spacing={{xs: 2, md: 4}} alignItems={'center'}>
                        <LoadingButton
                            loading={loading}
                            type={'submit'}
                            variant={'contained'}
                            size={'large'}
                            sx={{minWidth: 300, maxWidth: {xs: '100%', md: 350}}}
                            disabled={sent}>
                            {sent ? (
                                <Typography variant={'p1'}>Данные отправлены</Typography>
                            ) : (
                                <Typography variant={'p1'}>Подписаться на рассылку</Typography>
                            )}
                        </LoadingButton>
                        <Typography sx={{textAlign: {xs: 'center', md: 'start'}, maxWidth: 450}}>
                            Нажимая на кнопку, ты разрешаешь нам обрабатывать свои персональные данные.
                        </Typography>
                    </Stack>
                </form>
            </Stack>
        </Stack>
    );
};

export default SubscribeForm;
