export const blackTheme = {
    palette: {
        background: {
            default: '#212227',
            paper: '#212227',
            card: '#333333'
        },
        text: {
            primary: '#F4F5F9'
        },
        system: {
            50: '#F8F8FA',
            100: '#F4F5F9',
            200: '#F0F1F5',
            300: '#E4E6ED',
            400: '#D1D3DC',
            500: '#B6B8C1',
            600: '#9294A0',
            700: '#6E707A',
            800: '#4B4D58',
            850: '#333333',
            900: '#212227'
        },
        systemShift: {
            50: '#4B4D58',
            100: '#4B4D58',
            200: '#6E707A',
            300: '#6E707A',
            400: '#9294A0',
            500: '#9294A0',
            600: '#B6B8C1',
            700: '#D1D3DC',
            800: '#E4E6ED',
            900: '#F4F5F9'
        },
        divider: '#9294A0',
        error: {
            main: '#FF564E',
            100: '#FFEEED'
        },
        warning: {
            main: '#F0B606',
            100: '#FFF9E5'
        },
        success: {
            main: '#28BC00',
            100: '#E9F8E5'
        },
        inProcess: {
            main: '#009EF6',
            100: '#E9F8E5'
        },

        primary: {
            active: '#7A45EA',
            main: '#956AEE',
            medium: '#BCA2F4',
            light: '#D3C3F8'
        },
        primaryShift: {
            active: '#7A45EA',
            main: '#956AEE',
            medium: '#BCA2F4',
            light: '#D3C3F8'
        },
        mode: 'dark'
    }
};
