import React, {useContext} from 'react';
import {CardMedia, Stack, Tooltip, Typography} from '@mui/material';
import cover from '../image/cover.png';
import {formatNumber} from 'helpers/formaters';
import {TinodeContext} from 'context/tinodeContext';

const ChatHeader = ({contact, isUsersVisible, maxLength}) => {
    const {topicUsers} = useContext(TinodeContext);
    const chat = contact?.public;
    const usersLength = Object.keys(contact?._users || {})?.length - 1 || topicUsers.length - 1;

    const makeImageUrl = (photo) => {
        if (photo && typeof photo == 'object') {
            if (photo.ref) {
                return photo.ref;
            }
            if (photo.data && photo.type) {
                const mime = photo.type.startsWith('image/') ? photo.type : 'image/' + photo.type;
                return 'data:' + mime + ';base64,' + photo.data;
            }
        }
        return null;
    };
    return (
        <Stack direction={'row'} alignItems={'center'} spacing={2} py={1.5} px={1}>
            <CardMedia
                component={'img'}
                src={makeImageUrl(chat ? chat?.photo : null) || cover}
                sx={{
                    width: 50,
                    height: 50,
                    borderRadius: '50%',
                    backgroundColor: 'primary.medium'
                }}
            />
            <Stack spacing={0.5}>
                <Tooltip title={chat?.fn?.length > maxLength ? chat?.fn : ''}>
                    <Typography variant={'p4'} fontWeight={500} textTransform={'uppercase'}>
                        {chat?.fn?.length > maxLength ? chat?.fn?.slice(0, maxLength) + '...' : chat?.fn}
                    </Typography>
                </Tooltip>
                {isUsersVisible && !chat?.userId && (
                    <Typography variant={'p4'} height={20}>
                        {usersLength > 0 ? formatNumber(usersLength, ['участник', 'участника', 'участников']) : ''}
                    </Typography>
                )}
            </Stack>
        </Stack>
    );
};

export default ChatHeader;
