import {gql} from '@apollo/client';

export const GET_ACHIEVEMENTS = gql`
    query getAchievements {
        userAchievements {
            currentLevel
            description
            picture
            maxLevel
            name
            currentAchievementLevels {
                description
                isDone
                level
            }
        }
    }
`;
