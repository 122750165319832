import React from 'react';
import {Box, Container, Typography} from '@mui/material';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {useQuery} from '@apollo/client';
import {GET_EXPERTS} from 'graphql/landing/getExperts';
import CircularLoading from 'components/loading/CircularLoading';
import {Dot, LeftArrow, RightArrow} from 'components/CarouselComponents';
import ExpertCard from 'components/ExpertCard';

const RESPONSIVE_BREAKPOINTS = {
    desktop: {
        breakpoint: {max: 4000, min: 1200},
        items: 4,
        slidesToSlide: 1
    },
    tablet: {
        breakpoint: {max: 1200, min: 600},
        items: 3,
        slidesToSlide: 1
    },
    mobile: {
        breakpoint: {max: 600, min: 0},
        items: 1,
        slidesToSlide: 1
    }
};

const Experts = () => {
    const {data, loading, error} = useQuery(GET_EXPERTS);
    const experts = data?.landingExperts || [];

    return (
        <Container sx={{my: 5, position: 'relative'}}>
            <Typography variant='h3' mb={7} sx={{textAlign: {xs: 'center', lg: 'left'}}}>
                НАШИ ЭКСПЕРТЫ
            </Typography>
            <CircularLoading loading={loading || error}>
                {experts?.length > 0 ? <Box
                    sx={{
                        borderRadius: '20px',
                        overflow: 'hidden',
                        mx: 8,
                        '& .item': {px: 3},
                        '& .container': {mx: -3, mb: 6, position: 'static'},
                        '& .react-multi-carousel-dot-list': {
                            overflow: 'hidden',
                            maxWidth: {xs: '300px', sm: '100%'},
                            margin: 'auto'
                        }
                    }}>
                    <Carousel
                        responsive={RESPONSIVE_BREAKPOINTS}
                        showDots={true}
                        autoPlay={false}
                        shouldResetAutoplay={false}
                        infinite={true}
                        removeArrowOnDeviceType={['tablet', 'mobile']}
                        customLeftArrow={<LeftArrow left={65}/>}
                        customRightArrow={<RightArrow right={65}/>}
                        customDot={<Dot/>}
                        // renderButtonGroupOutside={true}
                        itemClass='item'
                        containerClass='container'>
                        {experts?.map((item) => (
                            <ExpertCard key={item.id} expert={item?.user}/>
                        ))}
                    </Carousel>
                </Box> : <Typography sx={{textAlign: 'center', textTransform: 'uppercase'}}>
                    Скоро здесь ты сможешь узнать о наших экспертах
                </Typography>}
            </CircularLoading>
        </Container>
    );
};

export default Experts;
