import {lazy, Suspense} from 'react';
import MainLayout from 'layouts/MainLayout';
import {Box, Container} from '@mui/material';
import Landing from 'pages/Landing';
import ResetPassword from 'pages/Auth/components/ResetPassword';
import Error from 'pages/Error';
import OrganizationInformation from 'pages/OrganizationInformation';

const Loadable = (Component) => (props) =>
    (
        <Suspense fallback={<Box height={'100vh'} />}>
            <Component {...props} />
        </Suspense>
    );

const Profile = Loadable(lazy(() => import('pages/Profile')));
const Auth = Loadable(lazy(() => import('pages/Auth')));
const ConfirmEmail = Loadable(lazy(() => import('pages/ConfirmEmail')));
const EmailSuccess = Loadable(lazy(() => import('pages/EmailSuccess')));
const AuthCallback = Loadable(lazy(() => import('pages/AuthCallback')));
const RegistrationSuccess = Loadable(lazy(() => import('pages/RegistrationSuccess')));
const Checkout = Loadable(lazy(() => import('pages/Checkout')));
const CoursesAndEvents = Loadable(lazy(() => import('components/CoursesAndEvents')));
const Events = Loadable(lazy(() => import('pages/Trajectory')));
const Event = Loadable(lazy(() => import('pages/Event')));
const Block = Loadable(lazy(() => import('pages/Event/components/Block')));
const About = Loadable(lazy(() => import('pages/About')));
const BlockPreview = Loadable(lazy(() => import('pages/Event/components/BlockPreview')));
const Calendar = Loadable(lazy(() => import('pages/Calendar')));
const FormIo = Loadable(lazy(() => import('pages/FormIo')));
const Services = Loadable(lazy(() => import('pages/Services')));
const Blog = Loadable(lazy(() => import('pages/Blog')));
const BlogPost = Loadable(lazy(() => import('pages/BlogPost')));
const Subscription = Loadable(lazy(() => import('components/Subscription')));
const CardsPreview = Loadable(lazy(() => import('pages/CardsPreview')));
const Chats = Loadable(lazy(() => import('pages/Chats')));

const routes = [
    {
        element: <MainLayout />,
        children: [
            {
                index: true,
                element: <Landing />
            },
            {
                path: 'information-about-the-educational-organization',
                element: <OrganizationInformation />
            },
            {
                path: 'auth',
                element: <Auth />
            },
            {
                path: 'auth/reset',
                element: <ResetPassword />
            },
            {
                path: 'reg',
                element: <Auth />
            },
            {
                path: 'profile',
                element: <Profile />
            },
            {
                path: 'email/confirm',
                element: <ConfirmEmail />
            },
            {
                path: 'email/success',
                element: <EmailSuccess />
            },
            {
                path: 'reg/success',
                element: <RegistrationSuccess />
            },
            {
                path: 'checkout/:resourceId',
                element: <Checkout />
            },
            {
                path: 'trajectory',
                element: <Events />
            },
            {
                path: 'events',
                children: [
                    {
                        path: 'preview',
                        children: [
                            {
                                path: ':eventId',
                                element: <Event />,
                                children: [
                                    {
                                        path: ':blockId',
                                        element: <BlockPreview />,
                                        children: [
                                            {
                                                path: ':elementId',
                                                element: <BlockPreview />
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: ':eventId',
                        element: <Event />,
                        children: [
                            {
                                path: ':blockId',
                                element: <Block />,
                                children: [
                                    {
                                        path: ':elementId',
                                        element: <Block />
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                path: 'about',
                element: <About />
            },
            {
                path: 'calendar',
                element: <Calendar />
            },
            {
                path: 'courses-and-events',
                element: <CoursesAndEvents />
            },
            {
                path: 'blog',

                children: [
                    {index: true, element: <Blog />},
                    {
                        path: ':postId',
                        element: <BlogPost />
                    },
                    {
                        path: 'preview/:postId',
                        element: <BlogPost />
                    }
                ]
            },
            {
                path: 'forms/:formName',
                element: <FormIo />
            },
            {
                path: 'forms/services',
                element: <Services />
            },
            {
                path: 'subscription',
                element: <Subscription linkWithTab />
            },
            {
                path: 'cards/preview',
                children: [
                    {
                        path: ':cardId',
                        element: <CardsPreview />
                    }
                ]
            },

            {
                path: 'public/block/:eventId/:blockId',
                element: (
                    <Container>
                        <Block />
                    </Container>
                ),
                children: [
                    {
                        path: ':elementId',
                        element: (
                            <Container>
                                <Block />
                            </Container>
                        )
                    }
                ]
            },
            {
                path: 'chats',
                children: [
                    {index: true, element: <Chats />},
                    {path: ':chatId', element: <Chats />}
                ]
            }
        ]
    },
    {
        path: 'auth/callback',
        element: <AuthCallback />
    },
    {path: '*', element: <Error />}
];
export default routes;
