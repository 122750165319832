import * as React from 'react';
import {useContext} from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import {NetworkIcon, TelegramIcon, VkIcon} from 'assets/icons';
import settingsContext from 'context/settingsContext';
import {THEMES} from 'constants/themes';
import {SvgIcon} from '@mui/material';
import {useWindowSize} from 'hooks/useWindowSize';

export const NetworkButton = () => {
    const {settings} = useContext(settingsContext);
    const {width} = useWindowSize();

    const BUTTONS = [
        {
            icon: (
                <SvgIcon
                    component={VkIcon}
                    inheritViewBox
                    width={25}
                    sx={{color: (theme) => (settings.theme === THEMES.DARK ? theme.palette.system[50] : '#2787F5')}}
                />
            ),
            name: 'VK',
            bg: (theme) => (settings.theme === THEMES.DARK ? theme.palette.system[850] : theme.palette.white),
            hover: {
                backgroundColor: (theme) =>
                    settings.theme === THEMES.DARK ? theme.palette.system[800] : theme.palette.system[100]
            },
            href: 'https://vk.com/medialabteam'
        },
        {
            icon: (
                <SvgIcon
                    component={TelegramIcon}
                    inheritViewBox
                    width={25}
                    sx={{color: (theme) => theme.palette.system[50], mr: 0.5}}
                />
            ),
            name: 'TELEGRAM',
            bg: (theme) => (settings.theme === THEMES.DARK ? theme.palette.system[850] : '#56A5FF'),
            hover: {
                backgroundColor: (theme) => (settings.theme === THEMES.DARK ? theme.palette.system[800] : '#2787F5')
            },
            href: 'https://t.me/medialabteam'
        }
    ];
    const OPTIONS_BUTTON = {
        sizeButton: {xs: 50, md: 60},
        sizeSvg: {xs: 30, md: 40},
        left: {xs: '100%', md: width * 0.98},
        bottom: {xs: 20, md: 40}
    };
    return (
        <Box
            sx={{
                flexGrow: 1,
                position: 'fixed',
                bottom: OPTIONS_BUTTON.bottom,
                left: OPTIONS_BUTTON.left,
                zIndex: 100
            }}>
            <SpeedDial
                ariaLabel='SpeedDial'
                sx={{
                    position: 'absolute',
                    bottom: 16,
                    right: 16,
                    '& .MuiSpeedDial-fab': {
                        width: OPTIONS_BUTTON.sizeButton,
                        height: OPTIONS_BUTTON.sizeButton
                    },
                    '& .MuiSpeedDial-fab svg': {
                        width: OPTIONS_BUTTON.sizeSvg
                    }
                }}
                icon={<NetworkIcon />}>
                {BUTTONS.map((button) => (
                    <SpeedDialAction
                        sx={{
                            width: OPTIONS_BUTTON.sizeButton,
                            height: OPTIONS_BUTTON.sizeButton,
                            background: button.bg,
                            '&:hover': button.hover
                        }}
                        onClick={() => window.open(button.href)}
                        key={button.name}
                        icon={button.icon}
                        tooltipTitle={button.name}
                    />
                ))}
            </SpeedDial>
        </Box>
    );
};
