import React from 'react';
import {Box, Collapse, Container} from '@mui/material';

const CustomCollapse = ({open, children, sx}) => {
    return (
        <Collapse
            in={open}
            timeout='auto'
            unmountOnExit
            sx={{
                ...sx,
                position: 'absolute',
                zIndex: 50,
                backgroundColor: 'background.default',
                width: '100%',
                left: 0,
                transition: 'height 10ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                borderBottom: (theme) => '1px solid' + theme.palette.divider,
                boxShadow: '0px 24px 24px rgb(140 152 165 / 13%)'
            }}>
            <Container>
                <Box pb={3}>{children}</Box>
            </Container>
        </Collapse>
    );
};

export default CustomCollapse;
