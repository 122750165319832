export const baseTheme = {
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: '0px 6px 24px rgba(140, 152, 165, 0.13)'
                }
            }
        },
        MuiLink: {
            defaultProps: {
                underline: 'none',
                color: 'secondary'
            },
            styleOverrides: {
                root: ({theme}) => ({
                    fontFamily: 'Roboto',
                    textTransform: 'uppercase',
                    color: theme.palette.text.primary,
                    cursor: 'pointer'
                })
            },
            variants: [
                {
                    props: {color: 'primary'},
                    style: ({theme}) => ({
                        textDecorationColor: theme.palette.primaryShift.main,
                        color: theme.palette.primaryShift.main,
                        textUnderlineOffset: '5px',
                        '&:hover': {
                            color: theme.palette.primaryShift.active,
                            textDecorationColor: theme.palette.primaryShift.active
                        }
                    })
                },
                {
                    props: {color: 'secondary'},
                    style: ({theme}) => ({
                        textDecorationColor: theme.palette.text.primary,
                        color: theme.palette.text.primary,
                        textUnderlineOffset: '5px',
                        '&:hover': {
                            color: theme.palette.systemShift[800],
                            textDecorationColor: theme.palette.systemShift[800]
                        }
                    })
                }
            ]
        },

        MuiListItem: {
            styleOverrides: {
                root: {
                    width: 'auto'
                }
            }
        },
        MuiContainer: {
            defaultProps: {
                maxWidth: 'xl'
            },
            styleOverrides: {
                root: ({theme}) => ({
                    [theme.breakpoints.down('md')]: {
                        padding: '0 20px'
                    },
                    [theme.breakpoints.up('md')]: {
                        padding: '0 60px'
                    }
                })
            }
        },

        MuiTabs: {
            defaultProps: {
                textColor: 'secondary',
                variant: 'scrollable',
                // scrollButtons: 'auto'
                // 'aria-label': 'scrollable auto tabs example'
            },
            styleOverrides: {
                flexContainer: {
                    gap: 15
                },
                root: {
                    minHeight: 25,
                    // overflow: 'visible'
                    // paddingBottom: 24
                },
                // scroller: {
                //     overflow: 'visible !important'
                // }
            }
        },
        MuiTabPanel: {
            styleOverrides: {
                root: {
                    padding: 0,
                    overflow: 'visible'
                }
            }
        },
        MuiTab: {
            defaultProps: {
                disableFocusRipple: true,
                disableRipple: true
            },
            styleOverrides: {
                root: ({theme}) => ({
                    padding: 0,
                    minWidth: 10,
                    minHeight: 0,
                    color: theme.palette.system[600],
                    '&.Mui-selected': {color: theme.palette.systemShift[900]},
                    overflow: 'visible'
                })
            }
        },
        MuiTabScrollButton: {
            defaultProps: {
                disableRipple: true
            },
            styleOverrides: {
                root: {
                    marginTop: -7
                }
            }
        },
        MuiRadio: {
            styleOverrides: {
                root: ({theme}) => ({
                    marginLeft: -10,
                    color: theme.palette.grey.light,
                    '&.Mui-checked': {
                        color: theme.palette.primary.main,
                        '& circle:first-of-type': {
                            fill: theme.palette.background.card + '!important'
                        }
                    }
                })
            }
        },

        MuiButton: {
            styleOverrides: {
                containedPrimary: ({theme}) => ({
                    border: '1px solid transparent',
                    backgroundColor: theme.palette.primary.main,
                    '&:hover': {
                        border: '1px solid transparent',
                        backgroundColor: theme.palette.primary.active
                    },
                    '&:disabled': {
                        border: '1px solid transparent',
                        color: theme.palette.systemShift[500],
                        backgroundColor: theme.palette.systemShift[50]
                    }
                }),
                outlinedPrimary: ({theme}) => ({
                    // backgroundColor: theme.palette.systemShift[50],
                    border: '1px solid ' + theme.palette.primaryShift.main,
                    color: theme.palette.primaryShift.main,
                    // '&:hover': {
                    //     backgroundColor: theme.palette.systemShift[200],
                    //     border: '1px solid ' + theme.palette.primary.active,
                    //     color: theme.palette.primary.active
                    // },
                    '&:disabled': {
                        // backgroundColor: theme.palette.systemShift[50],
                        border: '1px solid ' + theme.palette.systemShift[500],
                        color: theme.palette.systemShift[500]
                    }
                }),
                root: {
                    borderRadius: 5,
                    whiteSpace: 'nowrap',
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none'
                    },
                    // width: 'auto',
                    fontWeight: 400,
                    '@media (max-width: 900px)': {
                        width: '100%'
                    }
                },
                sizeSmall: {
                    padding: '12px 24px',
                    fontSize: 14,
                    lineHeight: '17px'
                },
                sizeMedium: {
                    fontSize: 16,
                    lineHeight: '22px',
                    padding: '12px 16px',
                    // minWidth: 200
                    '@media (max-width:900px)': {
                        fontSize: 14
                    }
                },
                sizeLarge: {
                    fontSize: 20,
                    lineHeight: '23px',
                    height: '52px',
                    width: 320,
                    '@media (max-width:900px)': {
                        fontSize: 16
                    }
                },

                textPrimary: ({theme}) => ({
                    border: 'none',
                    color: theme.palette.primaryShift.main,
                    backgroundColor: 'initial',
                    // '&:hover': {
                    //     border: 'none',
                    //     color: '#7A45EA',
                    //     backgroundColor: 'initial'
                    // },
                    '&:disabled': {
                        border: 'none',
                        backgroundColor: 'initial',
                        color: theme.palette.systemShift[500]
                        // color: theme.palette.primaryShift.active
                    }
                })
                // cancel: ({theme}) => ({
                //     border: '1px solid transparent',
                //     color: theme.palette.system[600],
                //     backgroundColor: theme.palette.system[300],
                //
                //     '&:hover': {
                //         border: '1px solid transparent',
                //         backgroundColor: theme.palette.system[400]
                //     },
                //     '&:disabled': {
                //         border: '1px solid transparent',
                //         backgroundColor: theme.palette.systemShift[100],
                //         color: theme.palette.systemShift[300]
                //     }
                // })
            }
        },
        MuiLoadingButton: {
            defaultProps: {
                loadingIndicator: 'Загрузка...'
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: ({theme}) => ({
                    // backgroundColor: 'inherit',
                    opacity: 1,
                    backgroundColor: theme.palette.systemShift[50],
                    '& input': {
                        paddingTop: '16px',
                        paddingBottom: '16px',
                        '&:-webkit-autofill': {
                            transition: 'background-color 5000s ease-in-out 0s',
                            boxShadow: 'inset 0 0 0 100px' + theme.palette.systemShift[50],
                            textFillColor: theme.palette.text.primary
                        }
                    },
                    '.MuiIconButton-root': {color: theme.palette.system[600]}
                }),
                input: ({theme}) => ({
                    ...theme.typography.p4,
                    color: theme.palette.systemShift[900],
                    '&:focus': {
                        borderColor: 'transparent',
                        outline: 'none'
                    },
                    '&::placeholder, &::placeholder': {
                        color: theme.palette.systemShift[600]
                    }
                }),
                colorSecondary: ({theme}) => ({
                    borderRadius: '5px !important',
                    paddingRight: '0 !important',
                    border: '1px solid',
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.primary.main
                    },
                    '&:not(.Mui-error):hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.primary.main + '!important'
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline, &.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.primary.main + '!important',
                        outline: 'none',
                        borderWidth: 1
                    }
                }),
                colorSuccess: ({theme}) => ({
                    borderRadius: '5px !important',
                    paddingRight: '0 !important',
                    border: '1px solid',
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.success.main
                    },
                    '&:not(.Mui-error):hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.success.main + '!important'
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline, &.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.success.main + '!important',
                        outline: 'none',
                        borderWidth: 1
                    },
                    color: theme.palette.success.main + '!important',
                    '& path': {
                        stroke: theme.palette.success.main + '!important'
                    },
                    '&.Mui-disabled': {
                        color: theme.palette.success.main + '!important',
                        '& div': {WebkitTextFillColor: 'inherit !important'}
                    }
                }),
                colorError: ({theme}) => ({
                    borderRadius: '5px !important',
                    paddingRight: '0 !important',
                    border: '1px solid',
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.error.main
                    },
                    '&:not(.Mui-error):hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.error.main + '!important'
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline, &.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.error.main + '!important',
                        outline: 'none',
                        borderWidth: 1
                    },
                    color: theme.palette.error.main + '!important',
                    '& path': {
                        stroke: theme.palette.error.main + '!important'
                    },
                    '&.Mui-disabled': {
                        '& div': {WebkitTextFillColor: theme.palette.systemShift[900] + '!important'}
                    }
                })
            }
        },
        MuiTooltip: {
            defaultProps: {
                enterTouchDelay: 50
            },
            styleOverrides: {
                tooltip: ({theme}) => ({
                    p: 2,
                    color: theme.palette.systemShift[900],
                    backgroundColor: theme.palette.systemShift[50],
                    fontSize: '14px !important',
                    lineHeight: '20px !important',
                    fontWeight: 400
                }),

                arrow: ({theme}) => ({
                    color: theme.palette.systemShift[50]
                })
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: ({theme}) => ({
                    ...theme.typography.h5,
                    textTransform: 'none',
                    color: theme.palette.primary.main
                })
            }
        },
        MuiSelect: {
            styleOverrides: {
                icon: ({theme}) => ({
                    '&.MuiSelect-icon': {
                        color: theme.palette.primaryShift.main
                    }
                }),
                select: {
                    fontSize: '14px',
                    paddingTop: '15px',
                    paddingBottom: '15px',
                    color: 'inherit'
                }
                // secondary: ({theme}) => ({})
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: '14px'
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: 12,
                    fontWeight: 400,
                    marginLeft: 0,
                    position: 'absolute',
                    bottom: -20
                }
            }
        },
        MuiAlert: {
            styleOverrides: {
                root: ({theme}) => ({
                    zIndex: 1000,
                    width: 350,
                    height: 84,
                    color: theme.palette.systemShift[900],
                    backgroundColor: theme.palette.systemShift[200]
                }),
                outlinedSuccess: ({theme}) => ({
                    border: 'none',
                    borderLeft: '6px solid ' + theme.palette.success.main
                }),
                outlinedError: ({theme}) => ({
                    border: 'none',
                    borderLeft: '6px solid ' + theme.palette.error.main
                }),
                icon: {
                    alignItems: 'center'
                    // fill: theme.palette.success.main
                },
                message: {alignItems: 'center', display: 'flex'}
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: ({theme}) => ({
                    // paddingRight: '10px',
                    borderColor: 'transparent',
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline, &.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent',
                        outline: 'none',
                        borderWidth: 1
                    },
                    '&:not(.Mui-error):hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent'
                    },
                    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.error.main
                    },
                    '& ::-moz-placeholder': {color: theme.palette.system[600], ...theme.typography.p4},
                    '& ::-webkit-input-placeholder': {color: theme.palette.system[600], ...theme.typography.p4}
                }),

                notchedOutline: {
                    outline: 'none',
                    borderColor: 'transparent'
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: ({theme}) => ({
                    '& .MuiChip-root': {
                        backgroundColor: 'transparent',
                        color: theme.palette.primaryShift.main + '!important',
                        borderRadius: '5px',
                        textTransform: 'uppercase',
                        height: '25px !important',
                        border: '1px solid' + theme.palette.primaryShift.main
                    },
                    '& .MuiChip-deleteIcon': {
                        color: theme.palette.primaryShift.main + '!important'
                    },
                    '& .MuiIconButton-root': {
                        width: 30,
                        height: 30
                    }
                }),
                endAdornment: {
                    top: 'auto'
                }
            }
        },
        MuiTypography: {
            defaultProps: {
                variant: 'p3',
                variantMapping: {
                    p1: 'p',
                    p2: 'p',
                    p3: 'p',
                    p4: 'p'
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: ({theme}) => ({
                    ...theme.typography.p3,
                    fontWeight: 400,
                    textTransform: 'uppercase'
                }),
                colorSuccess: ({theme}) => ({
                    color: theme.palette.success.main
                })
            }
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    marginLeft: 0,
                    marginRight: 2
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    padding: '8px !important'
                }
            }
        },
        MuiCheckbox: {
            defaultProps: {
                size: 'medium'
            },
            styleOverrides: {
                root: ({theme}) => ({
                    color: theme.palette.primary.main,
                    '&.Mui-disabled': {
                        color: theme.palette.primary.light
                    },
                    '&.Mui-checked': {
                        color: theme.palette.systemShift.main
                    }
                })
            }
        },
        MuiAccordion: {
            defaultProps: {
                variant: 'outlined',
                disableGutters: true
            },
            styleOverrides: {
                root: {
                    boxShadow: 'none'
                    // borderRadius: '5px !important'
                }
            }
        }
    }
};
