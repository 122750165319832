import React from 'react';
import {Stack, SvgIcon, Tooltip, Typography} from '@mui/material';
import * as MuiIcons from '@mui/icons-material';

const Icons = ({icons, isLg}) => {
    const maxLength = isLg ? 10 : 30;
    return (
        <Stack
            color={'system.600'}
            sx={{my: 'auto !important', px: 1}}
            spacing={1}
            minWidth={150}
            width={isLg ? 'auto' : 'fit-content'}>
            {icons?.map((icon) => (
                <Stack key={icon.id} direction={'row'} spacing={1} alignItems={'center'} width={'fit-content'}>
                    {MuiIcons[icon?.iconImage] ? <SvgIcon component={MuiIcons[icon?.iconImage]} /> : icon?.iconImage}
                    {icon?.description && (
                        <Tooltip title={icon.description.length > maxLength ? icon.description : ''}>
                            <Typography>
                                {icon.description.length > maxLength
                                    ? icon.description.slice(0, maxLength) + '...'
                                    : icon.description}
                            </Typography>
                        </Tooltip>
                    )}
                </Stack>
            ))}
        </Stack>
    );
};
export default Icons;
