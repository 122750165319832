import {INPUT_TYPE} from 'components/inputs/InputWithLabel';
import {MASKS} from 'constants/masks';

const REG_FIELDS = [
    {
        label: 'Фамилия',
        name: 'lastName',
        type: INPUT_TYPE.TEXT,
        mask: MASKS.NAME
    },
    {
        label: 'Имя',
        name: 'firstName',
        type: INPUT_TYPE.TEXT,
        mask: MASKS.NAME
    },
    {
        label: 'Отчество',
        name: 'middleName',
        type: INPUT_TYPE.TEXT,
        mask: MASKS.NAME
    },
    {
        label: 'Номер телефона',
        name: 'phoneNumber',
        type: INPUT_TYPE.TEXT,
        mask: MASKS.PHONE
    },
    {
        label: 'E-mail',
        name: 'email',
        type: INPUT_TYPE.TEXT
    },
    {
        label: 'Пароль',
        name: 'password',
        type: INPUT_TYPE.TEXT,
        // type: 'password',
        password: true
    },
    {
        label: 'Повторите пароль',
        name: 'confirmPassword',
        type: INPUT_TYPE.TEXT,
        // type: 'password',
        password: true
    }
];

const AUTH_FIELDS = [
    {
        label: 'E-mail',
        name: 'email',
        type: INPUT_TYPE.TEXT
    },
    {
        label: 'Пароль',
        name: 'password',
        type: INPUT_TYPE.TEXT,
        password: true
    }
];
const RESET_FIELDS = [
    {
        name: 'newPassword',
        label: 'Пароль',
        placeholder: 'Введите новый пароль'
    },
    {
        name: 'againPassword',
        label: 'Повторите пароль',
        placeholder: 'Повторите новый пароль'
    }
];

export {AUTH_FIELDS, REG_FIELDS, RESET_FIELDS};
