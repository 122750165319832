import {useMediaQuery} from '@mui/material';
import React from 'react';
import DesktopBlogCard from './DesktopBlogCard';
import MobileBlogCard from './MobileBlogCard';

const BlogCard = ({post}) => {
    const isMd = useMediaQuery((theme) => theme.breakpoints.up('md'));
    return isMd ? <DesktopBlogCard post={post} /> : <MobileBlogCard post={post} />;
};

export default BlogCard;
