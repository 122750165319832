import React from 'react';
import {MenuItem, TextField, Typography} from '@mui/material';
import {Controller} from 'react-hook-form';
import {ExpandLessIcon} from 'assets/icons';

const SelectControl = ({
    options,
    placeholder,
    control,
    name,
    fullWidth = true,
    variant = 'secondary',
    rules,
    disabled
}) => {
    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({field, fieldState: {error}}) => (
                <TextField
                    {...field}
                    id={'select'}
                    value={field.value || ''}
                    fullWidth={fullWidth}
                    error={!!error}
                    select
                    sx={{'& .MuiSelect-icon': {marginTop: '4px', marginRight: '10px'}}}
                    SelectProps={{
                        renderValue: (selected) =>
                            options?.find((i) => i.id === selected)?.displayName || (
                                <Typography
                                    color={'system.600'}
                                    sx={{WebkitTextFillColor: (theme) => theme.palette.system[600]}}
                                    variant={'body4'}>
                                    {placeholder}
                                </Typography>
                            ),
                        displayEmpty: true,
                        disabled: disabled,
                        IconComponent: ExpandLessIcon,
                        color: variant
                    }}>
                    {options?.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.displayName}
                        </MenuItem>
                    ))}
                </TextField>
            )}
        />
    );
};

export default SelectControl;
