import React, {useState} from 'react';
import {Box, Button, CardMedia, Grid, Stack, styled, Tooltip, Typography, useMediaQuery} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import LinesEllipsis from 'react-lines-ellipsis/lib/index.modern.mjs';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import {ROUTES} from 'constants/routes';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

const DesktopBlogCard = ({post}) => {
    const navigate = useNavigate();
    const isLg = useMediaQuery(() => '@media (min-width:1420px)');
    const [clampedTitle, setClampedTitle] = useState('');
    const [clampedDescription, setClampedDescription] = useState('');
    return (
        <StyledCard
            justifyContent={'space-between'}
            sx={{position: 'relative'}}
            onClick={() => navigate(ROUTES.BLOG + '/' + post?.nameTr)}>
            <Stack direction={'row'} spacing={2} mb={2} height={{lg: '185px', xs: '140px'}}>
                {post?.coverFile && (
                    <Box>
                        <CardMedia
                            component='img'
                            image={post?.coverFile?.url}
                            alt={post?.coverFile?.name || 'image'}
                            sx={{width: {lg: '185px', xs: '140px'}}}
                        />
                    </Box>
                )}
                <Box>
                    <Tooltip title={clampedTitle ? post?.name : ''}>
                        <Typography variant={'h5'} gutterBottom mt={-0.75}>
                            <ResponsiveEllipsis
                                text={post?.name}
                                maxLine={post?.coverFile ? (isLg ? '3' : '2') : isLg ? '4' : '2'}
                                trimRight
                                onReflow={(rleState) => setClampedTitle(rleState.clamped)}
                                ellipsis='...'
                                basedOn='letters'
                            />
                        </Typography>
                    </Tooltip>
                    <Tooltip title={clampedDescription ? post?.description : ''}>
                        <Typography color={'systemShift.700'}>
                            <ResponsiveEllipsis
                                text={post?.description}
                                maxLine={post?.coverFile ? (isLg ? '5' : '4') : isLg ? '6' : '5'}
                                trimRight
                                onReflow={(rleState) => setClampedDescription(rleState.clamped)}
                                ellipsis='...'
                                basedOn='letters'
                            />
                        </Typography>
                    </Tooltip>
                </Box>
            </Stack>
            <Grid
                container
                direction={'row'}
                width={'75%'}
                spacing={1}
                wrap={'wrap'}
                height={43}
                sx={{overflow: 'hidden'}}>
                {post?.blogPostInterests?.map((dir) => (
                    <Grid item key={dir?.interest?.id}>
                        <Button
                            size={'small'}
                            variant={'outlined'}
                            sx={{px: 1.25, py: 1, width: 'auto !important', flexShrink: 0}}>
                            {dir?.interest?.name}
                        </Button>
                    </Grid>
                ))}
            </Grid>
            {post?.publicationDate && (
                <Typography color={'primary'} sx={{position: 'absolute', right: 30, bottom: 35}}>
                    {moment(post?.editedAt).isAfter(moment(post?.publicationDate))
                        ? moment(post?.editedAt)?.format('DD.MM.YYYY')
                        : moment(post?.publicationDate)?.format('DD.MM.YYYY')}
                </Typography>
            )}
        </StyledCard>
    );
};

const StyledCard = styled(Stack)(({theme}) => ({
    border: '3px solid' + theme.palette.primary.medium,
    borderRadius: '25px',
    padding: '30px 38px',
    height: 315,
    cursor: 'pointer'
}));

export default DesktopBlogCard;
