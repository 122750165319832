import {
    ActiveLearningDefault,
    ActiveLearningDisabled,
    ActiveLearningMultiple,
    ActiveUserDefault,
    ActiveUserDisabled,
    ActiveUserMultiple,
    AddictedToMediaDefault,
    AddictedToMediaDisabled,
    AddictedToMediaMultiple,
    AmbassadorDefault,
    AmbassadorDisabled,
    AmbassadorMultiple,
    AuditorDefault,
    AuditorDisabled,
    AuditorMultiple,
    CommunicatorDefault,
    CommunicatorDisabled,
    CommunicatorMultiple,
    ConstructiveCriticDefault,
    ConstructiveCriticDisabled,
    ConstructiveCriticMultiple,
    ContentMakerDefault,
    ContentMakerDisabled,
    ContentMakerMultiple,
    EngagedSubscriberDefault,
    EngagedSubscriberDisabled,
    EngagedSubscriberMultiple,
    ExplorerDefault,
    ExplorerDisabled,
    ExplorerMultiple,
    FirstCourseDefault,
    FirstCourseDisabled,
    FirstCourseMultiple,
    FirstLessonDefault,
    FirstLessonDisabled,
    FirstLessonMultiple,
    InvestorInTheFutureDefault,
    InvestorInTheFutureDisabled,
    InvestorInTheFutureMultiple,
    MasterOfLearningDefault,
    MasterOfLearningDisabled,
    MasterOfLearningMultiple,
    OnlineMeetingDefault,
    OnlineMeetingDisabled,
    OnlineMeetingMultiple,
    PersonalAcquaintanceDefault,
    PersonalAcquaintanceDisabled,
    PersonalAcquaintanceMultiple,
    PlanningGuruDefault,
    PlanningGuruDisabled,
    PlanningGuruMultiple,
    ProfileDefault,
    ProfileDisabled,
    ProfileMultiple,
    PublicPersonDefault,
    PublicPersonDisabled,
    PublicPersonMultiple,
    RhombActive,
    RhombDisabled,
    TeamPlayerDefault,
    TeamPlayerDisabled,
    TeamPlayerMultiple,
    UniversalMediaWorkerDefault,
    UniversalMediaWorkerDisabled,
    UniversalMediaWorkerMultiple
} from 'assets/icons/achievement';
export const RHOMB_TYPE = {
    default: RhombActive,
    disabled: RhombDisabled
};
export const ACHIEVEMENT_TYPE = {
    FIRST_LESSON: {
        default: FirstLessonDefault,
        multiple: FirstLessonMultiple,
        disabled: FirstLessonDisabled
    },
    INVESTOR_IN_FUTURE: {
        default: InvestorInTheFutureDefault,
        multiple: InvestorInTheFutureMultiple,
        disabled: InvestorInTheFutureDisabled
    },
    CONSTRUCTIVE_CRITIC: {
        default: ConstructiveCriticDefault,
        multiple: ConstructiveCriticMultiple,
        disabled: ConstructiveCriticDisabled
    },
    PUBLIC_PERSON: {
        default: PublicPersonDefault,
        multiple: PublicPersonMultiple,
        disabled: PublicPersonDisabled
    },
    EXPLORER: {
        default: ExplorerDefault,
        multiple: ExplorerMultiple,
        disabled: ExplorerDisabled
    },
    TEAM_PLAYER: {
        default: TeamPlayerDefault,
        multiple: TeamPlayerMultiple,
        disabled: TeamPlayerDisabled
    },
    PLANNING_GURU: {
        default: PlanningGuruDefault,
        multiple: PlanningGuruMultiple,
        disabled: PlanningGuruDisabled
    },
    ACTIVE_USER: {
        default: ActiveUserDefault,
        multiple: ActiveUserMultiple,
        disabled: ActiveUserDisabled
    },
    ENGAGED_SUBSCRIBER: {
        default: EngagedSubscriberDefault,
        multiple: EngagedSubscriberMultiple,
        disabled: EngagedSubscriberDisabled
    },
    ACTIVE_LEARNING: {
        default: ActiveLearningDefault,
        multiple: ActiveLearningMultiple,
        disabled: ActiveLearningDisabled
    },
    FIRST_COURSE: {
        default: FirstCourseDefault,
        multiple: FirstCourseMultiple,
        disabled: FirstCourseDisabled
    },
    PROFILE: {
        default: ProfileDefault,
        multiple: ProfileMultiple,
        disabled: ProfileDisabled
    },
    PERSONAL_ACQUAINTANCE: {
        default: PersonalAcquaintanceDefault,
        multiple: PersonalAcquaintanceMultiple,
        disabled: PersonalAcquaintanceDisabled
    },
    CONTENT_MAKER: {
        default: ContentMakerDefault,
        multiple: ContentMakerMultiple,
        disabled: ContentMakerDisabled
    },
    ONLINE_MEETING: {
        default: OnlineMeetingDefault,
        multiple: OnlineMeetingMultiple,
        disabled: OnlineMeetingDisabled
    },
    MASTER_OF_LEARNING: {
        default: MasterOfLearningDefault,
        multiple: MasterOfLearningMultiple,
        disabled: MasterOfLearningDisabled
    },
    COMMUNICATOR: {
        default: CommunicatorDefault,
        multiple: CommunicatorMultiple,
        disabled: CommunicatorDisabled
    },
    UNIVERSAL_MEDIA_WORKER: {
        default: UniversalMediaWorkerDefault,
        multiple: UniversalMediaWorkerMultiple,
        disabled: UniversalMediaWorkerDisabled
    },
    AMBASSADOR: {
        default: AmbassadorDefault,
        multiple: AmbassadorMultiple,
        disabled: AmbassadorDisabled
    },
    AUDITOR: {
        default: AuditorDefault,
        multiple: AuditorMultiple,
        disabled: AuditorDisabled
    },
    ADDICTED_TO_MEDIA: {
        default: AddictedToMediaDefault,
        multiple: AddictedToMediaMultiple,
        disabled: AddictedToMediaDisabled
    }
};
