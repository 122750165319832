import {gql} from '@apollo/client';

export const GET_BANNER = gql`
    query getBanner {
        landingInformation(type: BANNER) {
            id
            color
            link
            file {
                id
                url
            }
        }
    }
`;
