import * as React from 'react';
import {Box} from '@mui/material';
import {Outlet} from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import {NetworkButton} from './NetworkButton';
import Banner from './Banner';

const MainLayout = () => {
    return (
        <Box display={'flex'} width={'100%'} maxHeight={'100%'} flexDirection={'column'} minHeight={'100vh'}>
            <Banner />
            <Header />
            <Box pb={{xs: 7, lg: 14}} pt={{xs: 4, lg: 6}}>
                {/*<Toolbar />*/}
                <Outlet />
                <NetworkButton />
            </Box>
            <Footer />
        </Box>
    );
};

export default MainLayout;
