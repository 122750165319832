import React, {useContext, useEffect, useState} from 'react';
import styled from '@emotion/styled';
import {Box, Tab, Typography} from '@mui/material';
import {NotificationContext} from 'context/notificationContext';
import {useUnseenCount} from '@novu/notification-center';

const Counter = ({count}) => {
    return count ? (
        <Box sx={{backgroundColor: 'error.main', ml: 0.5, borderRadius: 16, px: 0.75, py: 0.1}}>
            <Typography fontWeight={500} color={'white'} lineHeight={'16px'} fontSize={'12px'}>
                {count > 99 ? '99+' : count}
            </Typography>
        </Box>
    ) : null;
};

const NotificationTab = (props) => {
    const {apiService, initialized} = useContext(NotificationContext);
    const {unseenCount} = useUnseenCount();
    const [count, setCount] = useState();

    useEffect(async () => {
        if (initialized) {
            const {count} = await apiService.getUnseenCount({
                feedIdentifier: props.value === 'ALL' ? undefined : props.value
            });
            setCount(count);
        }
    }, [unseenCount]);

    return <StyledTab icon={<Counter count={count} />} iconPosition={'end'} {...props} />;
};

const StyledTab = styled(Tab)(({theme}) => ({
    minWidth: 0,
    minHeight: 0,
    padding: theme.spacing(1),
    opacity: 1
}));

export default NotificationTab;
