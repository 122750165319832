export const INTEREST_STATUS_ENUM = {
    BASE: 'BASE',
    BEGINNER: 'BEGINNER',
    EXPERT: 'EXPERT'
};

export const INTEREST_STATUS = {
    BEGINNER: 'Начальный',
    BASE: 'Средний',
    ADVANCED: 'Продвинутый',
    EXPERT: 'Продвинутый'
};

export const SUBSCRIPTION_STATUS_ENUM = {
    NO_SUBSCRIPTION: 'NO_SUBSCRIPTION',
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    EXPIRE_SOON: 'EXPIRE_SOON'
};

export const SUBSCRIPTION_STATUS = {
    ACTIVE: {label: 'Активная', color: '#28BC00'},
    EXPIRE_SOON: {label: 'Скоро закончится', color: '#F0B606'},
    INACTIVE: {label: 'Приостановлена', color: '#FF564E'}
};
