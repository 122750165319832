import {gql} from '@apollo/client';

export const GET_EXPERTS = gql`
    query getExperts {
        landingExperts {
            id
            order
            userId
            user {
                id
                firstName
                lastName
                about
                avatarFile {
                    id
                    url
                }
            }
        }
    }
`;
