import React, {useEffect, useMemo} from 'react';
import {Box, Button, Container, Grid, Stack, Typography} from '@mui/material';
import CoursesAndEventsCard from '../eventCards/CoursesAndEventsCard';
import {ROUTES} from 'constants/routes';
import {Link, useLocation, useSearchParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {GET_CARDS} from 'graphql/cards/getCards';
import CircularLoading from '../loading/CircularLoading';
import {CARD_OUTPUT_TYPE_ENUM} from 'constants/cards';
import SubscribeForm from 'components/SubscribeForm';
import SmallContactCard from 'components/SmallContactCard';
import InterestsFilter from "components/InterestsFilter";
import qs from "qs";
import {useForm} from "react-hook-form";
import {cleanData} from "helpers";

const CoursesAndEvents = () => {
    const location = useLocation();
    const isCoursesAndEvents = location.pathname === ROUTES.COURSES_AND_EVENTS;
    const {data, loading, error, refetch} = useQuery(GET_CARDS, {
        variables: {type: isCoursesAndEvents ? CARD_OUTPUT_TYPE_ENUM.COURSES : CARD_OUTPUT_TYPE_ENUM.LANDING}
    });
    const cards = data?.cards?.filter(card => isCoursesAndEvents ? !card.isHiddenForCourses : !card.isHiddenForLanding);

    const [searchParams, setSearchParams] = useSearchParams();

    const params = useMemo(() => qs.parse(searchParams.toString()), [searchParams]);
    const {interests} = params
    const form = useForm();
    const {watch, reset} = form;
    const watchInterests = watch('interests');

    useEffect(() => {
        const interestFilter = !!interests ? {interestId: {values: interests}} : undefined;
        refetch({
            filter: !!interests ? {
                OR: [{event: {eventInterests: interestFilter}}, {eventNote: {eventNoteInterests: interestFilter}}]
            } : undefined
        });
    }, [params]);

    useEffect(() => {
        reset({
            interests: interests || [],
        });
    }, []);

    const applyPostsFilter = () => {
        setSearchParams(qs.stringify(cleanData({...params, interests: watchInterests})))
    };

    const resetPostsFilter = () => {
        const defaultValues = {interests: []};
        reset(defaultValues);
        setSearchParams(qs.stringify(defaultValues));
    };


    return (
        <Container>
            <Typography
                variant={isCoursesAndEvents ? 'h2' : 'h3'}
                mb={7}
                sx={{textAlign: isCoursesAndEvents ? 'left' : {xs: 'center', lg: 'left'}}}>
                Курсы и события
            </Typography>
            {isCoursesAndEvents &&
                <Box mb={8} border={(theme) => '1px solid' + theme.palette.primary.medium} borderRadius={'25px'}
                     px={{xs: 4, md: 12}}
                     py={4}>
                    <InterestsFilter form={form}/>
                    <Stack direction={'row'} justifyContent={'flex-end'} spacing={2} mt={2}>
                        <Button onClick={resetPostsFilter} size={'small'}>
                            Сбросить
                        </Button>
                        <Button variant={'contained'} onClick={applyPostsFilter} size={'small'}>
                            Применить
                        </Button>
                    </Stack>
                </Box>}
            <CircularLoading loading={loading || error}>
                <Stack justifyContent={'center'} alignItems={'center'} mb={7} spacing={3} gap>
                    {!!cards?.length ? (
                        cards?.map((card) => <CoursesAndEventsCard card={card} key={card?.id}/>)
                    ) : (
                        <Typography sx={{textAlign: 'center', textTransform: 'uppercase'}}>
                            Скоро здесь ты сможешь узнать информацию о курсах и событиях
                        </Typography>
                    )}
                </Stack>
            </CircularLoading>
            {!isCoursesAndEvents && (
                <Stack mx={'auto'} my={4} alignItems={'center'}>
                    <Button
                        variant={'contained'}
                        size={'large'}
                        component={Link}
                        sx={{width: 'fit-content'}}
                        to={ROUTES.COURSES_AND_EVENTS}>
                        Все курсы и события
                    </Button>
                </Stack>
            )}
            {isCoursesAndEvents && (
                <Grid container spacing={{xs: 4, lg: 3}}>
                    <Grid item xs={12} lg={8}>
                        <SubscribeForm/>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <SmallContactCard/>
                    </Grid>
                </Grid>
            )}
        </Container>
    );
};

export default CoursesAndEvents;
