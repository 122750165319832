import {Container, Link, styled, Typography} from '@mui/material';
import {useQuery} from '@apollo/client';
import {GET_ORGANIZATION_INFO} from 'graphql/landing/getOrganizationInfo';
import Text from 'components/ElementsGenerator/components/Text';
import CircularLoading from 'components/loading/CircularLoading';

const OrganizationInformation = () => {
    const {data, loading} = useQuery(GET_ORGANIZATION_INFO);
    const content = data?.landingInformation?.content;

    return (
        <CircularLoading loading={loading}>
            <Container sx={{lineHeight: 2,'& p': {lineHeight: 1.7}}}>
                <Typography variant={'h2'} textAlign={'center'}>Сведения об образовательной организации</Typography>
                <Text customText={content}/>
            </Container>
        </CircularLoading>
    );
};

export default OrganizationInformation;
