import React from 'react';
import {Autocomplete, TextField} from '@mui/material';
import {Controller} from 'react-hook-form';
import {CloseIcon, ExpandLessIcon} from 'assets/icons';
import {Box} from '@mui/system';

const AutocompleteControl = ({
    options,
    control,
    name,
    label,
    fullWidth = true,
    optionName = 'displayName',
    multiple,
    disabled,
    onInputChange,
    placeholder,
    search,
    limitTags,
    equalityParam = 'id',
    freeSolo,
    sx
}) => {
    return (
        <Controller
            control={control}
            name={name}
            render={({field, fieldState: {error}}) => {
                return (
                    <Autocomplete
                        {...field}
                        id={'autocomplete'}
                        sx={sx}
                        value={multiple ? field.value || [] : field.value || null}
                        multiple={multiple}
                        limitTags={limitTags}
                        fullWidth={fullWidth}
                        freeSolo={freeSolo}
                        options={options || []}
                        filterOptions={search ? (x) => x : undefined}
                        disabled={disabled}
                        noOptionsText='Нет данных'
                        getOptionLabel={(option) => option[optionName] || ''}
                        ChipProps={{
                            deleteIcon: <CloseIcon />
                        }}
                        renderInput={(params) => (
                            <TextField
                                placeholder={placeholder || label}
                                helperText={error?.message}
                                error={!!error}
                                {...params}
                            />
                        )}
                        renderOption={(props, option) => (
                            <Box component='li' {...props} key={option.id}>
                                {option[optionName]}
                            </Box>
                        )}
                        popupIcon={<ExpandLessIcon />}
                        isOptionEqualToValue={(option, value) => option[equalityParam] === value[equalityParam]}
                        onChange={(_, data, reason) =>
                            reason === 'reset' ? field.onChange(null) : field.onChange(data)
                        }
                        onInputChange={onInputChange}
                    />
                );
            }}
        />
    );
};

export default AutocompleteControl;
