import {FormControl, FormGroup, FormHelperText, Stack} from '@mui/material';
import React from 'react';
import CheckboxControl from './CheckboxControl';

const CheckboxGroupControl = ({control, name, options = [], defaultValue, error, disabled}) => {
    return (
        <FormControl error={error} disabled={disabled}>
            <Stack direction={'column'} justifyContent={'flex-start'} alignItems={'flex-start'} position={'relative'}>
                <FormGroup>
                    {options?.map(
                        (item, index) =>
                            !item?.hidden && (
                                <CheckboxControl
                                    key={`checkbox-${index}`}
                                    name={`${name}.${item?.value}`}
                                    control={control}
                                    label={item?.label}
                                    defaultValue={defaultValue}
                                    disabled={item?.disabled}
                                />
                            )
                    )}
                </FormGroup>
                {error?.message && (
                    <FormHelperText error sx={{width: 'max-content'}}>
                        {error?.message}
                    </FormHelperText>
                )}
            </Stack>
        </FormControl>
    );
};

export default CheckboxGroupControl;
