import {gql} from '@apollo/client';

export const GET_INTERESTS = gql`
    query getInterests {
        interests {
            id
            name
        }
    }
`;
