export const POSTS_TYPE_ENUM = {
    ALL: 'ALL',
    ANNOUNCEMENT: 'ANNOUNCEMENT',
    NEWS: 'NEWS',
    TRENDS: 'TRENDS',
    SUCCESS_HISTORY: 'SUCCESS_HISTORY'
};
export const POSTS_TYPE = {
    ALL: 'Все',
    ANNOUNCEMENT: 'Анонсы',
    NEWS: 'Новости',
    TRENDS: 'Тренды',
    SUCCESS_HISTORY: 'Истории успеха'
};
