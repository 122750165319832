import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {LoadingButton} from '@mui/lab';

const CustomModal = ({
    open,
    onClose,
    title,
    content,
    handleClick,
    customButton,
    actionText,
    closeText,
    closeButtonVariant,
    disableBackdrop,
    sxDialogContent,
    sxDialog,
    loading
}) => {
    return (
        <Dialog
            open={open}
            onClose={(e, reason) => {
                disableBackdrop ? reason !== 'backdropClick' && onClose() : onClose();
            }}
            sx={{
                '& .MuiPaper-root': {
                    px: {xs: 2, sm: 7},
                    py: {xs: 2, sm: 4},
                    borderRadius: '25px',
                    width: {sm: 550},
                    ...sxDialog
                }
            }}>
            <DialogTitle
                id='alert-dialog-title'
                typography={'h6'}
                sx={{
                    px: content ? 0 : 'auto',
                    mx: 4,
                    textAlign: 'center',
                    pt: 0,
                    overflowWrap: 'break-word',
                    maxWidth: '100%'
                }}>
                {title}
                <IconButton
                    aria-label='close'
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: {xs: 8, sm: 24},
                        top: {xs: 8, sm: 24},
                        color: (theme) => theme.palette.grey[500]
                    }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{px: 0, pb: 3, '& :first-of-type': {mt: 0}, ...sxDialogContent}}>
                {content}
            </DialogContent>
            <DialogActions sx={{p: 0}}>
                <Grid container justifyContent={closeText && actionText ? 'space-between' : 'center'}>
                    {customButton || (
                        <>
                            {closeText && (
                                <Grid item xs={12} sm={5.8} sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Button
                                        onClick={() => onClose()}
                                        fullWidth={true}
                                        variant={closeButtonVariant}
                                        sx={{minWidth: 'fit-content'}}>
                                        {closeText}
                                    </Button>
                                </Grid>
                            )}
                            {actionText && (
                                <Grid item xs={12} sm={5.8} mb={{xs: 2, sm: 'auto'}}>
                                    <LoadingButton
                                        variant={'contained'}
                                        onClick={() => {
                                            handleClick();
                                        }}
                                        loading={loading}
                                        fullWidth={true}>
                                        {actionText}
                                    </LoadingButton>
                                </Grid>
                            )}
                        </>
                    )}
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default CustomModal;
