import React from 'react';
import {Typography, Stack, Grid, Button, Link, SvgIcon, useMediaQuery, Drawer, IconButton} from '@mui/material';
import CustomModal from 'components/modal/CustomModal';
import {MailIcon, PhoneIcon} from 'assets/icons';
import CloseIcon from '@mui/icons-material/Close';

const Services = ({openServices, setOpenServices}) => {
    const handleDrawerToggle = () => {
        setOpenServices(!openServices);
    };
    const isLg = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const CARDS = [
        {
            text: 'онлайн-трансляции, создание лекций и курсов, видеосъёмка, проведение вебинаров',
            button: 'подробнее',
            action: () => window.open('http://medialabprod.ru'),
            contacts: [
                {title: ' +7 (999) 842-32-51', icon: PhoneIcon, href: 'tel:+79998423251'},
                {title: 'production@medialab.team', icon: MailIcon, href: 'mailto:production@medialab.team'}
            ]
        },
        {
            text: 'Разработка онлайн-курсов \nи образовательных программ',
            button: 'подробнее',
            action: () => window.open('https://edu.medialab.team'),
            contacts: [
                {title: '+7 (999) 840-49-90', icon: PhoneIcon, href: 'tel:+79998404990'},
                {title: 'edu@medialab.team', icon: MailIcon, href: 'mailto:edu@medialab.team'}
            ]
        }
    ];

    const Content = () => {
        return (
            <Stack spacing={{xs: 4, lg: 2}}>
                {CARDS.map((item, index) => (
                    <Grid container rowGap={{xs: 2, lg: 4}}>
                        <Grid item xs={12} lg={6}>
                            <Stack
                                key={item.id}
                                sx={{
                                    border: (theme) => '3px solid ' + theme.palette.primary.medium,
                                    borderRadius: '25px',
                                    py: 3,
                                    px: 3
                                }}>
                                <Typography variant={'p4'} sx={{fontWeight: 500, textTransform: 'uppercase', mb: 2}}>
                                    {item.text}
                                </Typography>
                                <Button
                                    size={'large'}
                                    variant={'contained'}
                                    onClick={() => item.action()}
                                    sx={{width: 'auto'}}>
                                    {item.button}
                                </Button>
                            </Stack>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            lg={6}
                            sx={{
                                pl: {xs: 2, lg: 4},
                                pr: {xs: 2, lg: 0},
                                ...(index !== 0 && {display: 'flex', alignItems: 'center'})
                            }}>
                            <Stack gap={2}>
                                {index === 0 && (
                                    <Typography
                                        variant={'p2'}
                                        color={'systemShift.700'}
                                        sx={{textTransform: 'uppercase'}}>
                                        Мы всегда рады ответить на вопросы и обсудить детали
                                    </Typography>
                                )}
                                <Typography variant={'p2'} sx={{textTransform: 'uppercase', fontWeight: 500}}>
                                    Telegram/WhatsApp
                                </Typography>
                                {item.contacts.map((item) => (
                                    <Stack direction={'row'} spacing={1.5}>
                                        <SvgIcon component={item.icon} sx={{fill: 'none'}} />
                                        <Link variant={'p2'} href={item.href}>
                                            {item.title}
                                        </Link>
                                    </Stack>
                                ))}
                            </Stack>
                        </Grid>
                    </Grid>
                ))}
            </Stack>
        );
    };
    return (
        <>
            {isLg ? (
                <CustomModal
                    open={openServices}
                    onClose={() => setOpenServices(false)}
                    title={'Услуги'}
                    sxDialog={{width: 750, px: 4, maxWidth: 750}}
                    sxDialogContent={{pb: 0}}
                    content={<Content />}
                />
            ) : (
                <Drawer
                    sx={{height: '100%'}}
                    PaperProps={{sx: {height: '100%'}}}
                    anchor={'bottom'}
                    open={openServices}
                    onClose={handleDrawerToggle}>
                    <Stack gap={2} sx={{px: 4, py: 2}}>
                        <IconButton
                            aria-label='close'
                            onClick={handleDrawerToggle}
                            sx={{
                                p: '0 !important',
                                width: 24,
                                ml: 'auto',
                                color: (theme) => theme.palette.grey[500]
                            }}>
                            <CloseIcon />
                        </IconButton>
                        <Content />
                    </Stack>
                </Drawer>
            )}
        </>
    );
};

export default Services;
