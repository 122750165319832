import React, {useState} from 'react';
import {
    Box,
    Collapse,
    Container,
    Grid,
    List,
    ListItemButton,
    ListItemText,
    Stack,
    styled,
    SvgIcon,
    Typography,
    useMediaQuery
} from '@mui/material';
import ReactPlayer from 'react-player';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeMuteIcon from '@mui/icons-material/VolumeMute';
import {ExpandLessIcon, ExpandMoreIcon} from 'assets/icons';
import mask from '../icons/video-mask.svg';
import {COURSES} from 'mockData';
import {BackgroundVideoIcon, DecorationCircleIcon, DecorationStarIcon} from '../icons';

const Ecosystem = () => {
    const isLg = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const [course, setCourse] = useState(COURSES[3]);
    const [open, setOpen] = useState(null);
    const [playing, setPlaying] = useState(true);
    const [openPlaying, setOpenPlaying] = useState(false);
    const [muted, setMuted] = useState(true);

    const handleClick = (index) => {
        setOpen(open === index ? null : index);
    };

    return (
        <>
            <Container>
                <Typography variant='h2' sx={{textAlign: {xs: 'center', lg: 'left'}}}>
                    Лаборатория медиа <br />
                    Экосистема
                </Typography>
                <Grid container sx={{my: 7}}>
                    <Grid xs={12} lg={6} item id='hover'>
                        <Typography
                            variant='p1'
                            sx={{
                                maxWidth: {xs: 'auto', lg: 550},
                                textAlign: {xs: 'center', lg: 'left'},
                                textTransform: 'uppercase'
                            }}>
                            Поддерживаем блогеров, обучаем медийщиков, развиваем региональные контент-проекты, готовим
                            кадры для креативных индустрий.
                        </Typography>
                        {isLg && (
                            <Box>
                                <Stack direction={'row'} sx={{mt: 8, mb: 4, maxWidth: 600, flexWrap: 'wrap'}}>
                                    {COURSES.map((item, index) => (
                                        <StyledTitle
                                            key={item.id}
                                            onMouseEnter={() => setCourse(item)}
                                            active={course.id - 1 === index}>
                                            <Typography>{item.title}</Typography>
                                        </StyledTitle>
                                    ))}
                                </Stack>
                                <Typography id='descr' sx={{maxWidth: '95%', height: 120}}>
                                    {course.description}
                                </Typography>
                            </Box>
                        )}
                    </Grid>
                    <Grid xs={12} lg={6} item sx={{my: {xs: 5}, mt: {lg: -6}}}>
                        <Box sx={{position: 'relative'}}>
                            <BackgroundVideoIcon />
                            <Box sx={{zIndex: '10', top: '11%', left: '10%', position: 'absolute'}}>
                                <DecorationCircleIcon />
                            </Box>
                            <Box sx={{zIndex: '10', top: '20%', left: '91%', position: 'absolute'}}>
                                <DecorationStarIcon />
                            </Box>
                            <Box sx={{zIndex: '10', top: '82%', left: '69%', position: 'absolute'}}>
                                <DecorationCircleIcon />
                            </Box>

                            <ReactPlayer
                                style={{
                                    WebkitMaskBoxImage: `url(${mask})`,
                                    mask: `url(${mask})`,
                                    position: 'absolute',
                                    top: '13%',
                                    left: '3%',
                                    zIndex: '9',
                                    maskRepeat: 'no-repeat',
                                    maskClip: 'no-clip'
                                }}
                                width={'90% !important'}
                                height={'auto'}
                                onMouseEnter={() => setOpenPlaying(true)}
                                onMouseLeave={() => setOpenPlaying(false)}
                                playsinline={true}
                                controls={false}
                                playing={playing}
                                preload='auto'
                                volume={0.5}
                                muted={muted}
                                loop
                                url='https://medialab.team/files/Rolik-2-v05-10.11.mp4'
                            />
                            {playing ? (
                                <StyledPlay
                                    component={PauseIcon}
                                    openPlaying={openPlaying}
                                    onClick={() => setPlaying(!playing)}
                                    onMouseEnter={() => setOpenPlaying(true)}
                                />
                            ) : (
                                <StyledPlay
                                    component={PlayArrowIcon}
                                    openPlaying={openPlaying}
                                    onClick={() => setPlaying(!playing)}
                                    onMouseEnter={() => setOpenPlaying(true)}
                                />
                            )}
                            {muted ? (
                                <StyledSound
                                    component={VolumeMuteIcon}
                                    openPlaying={openPlaying}
                                    onClick={() => setMuted(!muted)}
                                    onMouseEnter={() => setOpenPlaying(true)}
                                />
                            ) : (
                                <StyledSound
                                    component={VolumeUpIcon}
                                    openPlaying={openPlaying}
                                    onClick={() => setMuted(!muted)}
                                    onMouseEnter={() => setOpenPlaying(true)}
                                />
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            {!isLg && (
                <Box mb={5}>
                    <List component='nav' sx={{width: '100%'}}>
                        {COURSES.map((item, index) => (
                            <Box
                                key={item.id}
                                sx={{
                                    borderBottom: (theme) => '1px solid' + theme.palette.systemShift['400'],
                                    '&:first-of-type': {
                                        borderTop: (theme) => '1px solid' + theme.palette.systemShift['400']
                                    }
                                }}>
                                <ListItemButton
                                    onClick={() => handleClick(index)}
                                    disableRipple
                                    sx={{cursor: 'pointer', mx: 4, '&:hover': {background: 'none'}}}>
                                    <ListItemText
                                        primary={<Typography fontWeight={700}>{item.title}</Typography>}
                                        sx={{textTransform: 'uppercase'}}
                                    />
                                    <Box color={'primaryShift.main'}>
                                        {open === index ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                                    </Box>
                                </ListItemButton>
                                <Collapse in={open === index} timeout='auto' unmountOnExit sx={{mx: 4}}>
                                    <List component='div' disablePadding>
                                        <ListItemButton
                                            disableRipple
                                            sx={{cursor: 'auto', '&:hover': {background: 'none'}}}>
                                            <ListItemText primary={<Typography>{item.description}</Typography>} />
                                        </ListItemButton>
                                    </List>
                                </Collapse>
                            </Box>
                        ))}
                    </List>
                </Box>
            )}
        </>
    );
};

const StyledTitle = styled(Box, {shouldForwardProp: (prop) => prop !== 'active'})(({active, theme}) => ({
    textTransform: 'uppercase',
    marginBottom: 7,
    marginRight: 7,
    width: 'max-content',
    borderRadius: '13px',
    fontSize: 14,
    cursor: 'pointer',
    padding: '5px 10px',
    transition: 'all 0.3s',
    border: active ? '2px solid' + theme.palette.primaryShift.main : '2px solid' + theme.palette.systemShift['900'],
    '&:hover': {
        border: (theme) => '2px solid' + theme.palette.primaryShift.main
    }
}));

const StyledPlay = styled(SvgIcon, {shouldForwardProp: (prop) => prop !== 'openPlaying'})(({openPlaying, theme}) => ({
    display: openPlaying ? 'flex' : 'none',
    color: theme.palette.system[50],
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '10',
    [theme.breakpoints.up('sm')]: {
        fontSize: 60
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: 40
    }
}));
const StyledSound = styled(SvgIcon, {shouldForwardProp: (prop) => prop !== 'openPlaying'})(({openPlaying, theme}) => ({
    display: openPlaying ? 'flex' : 'none',
    color: theme.palette.system[50],
    position: 'absolute',
    bottom: '15%',
    right: '7%',
    zIndex: '10',
    [theme.breakpoints.up('sm')]: {
        fontSize: 30
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: 25
    }
}));

export default Ecosystem;
