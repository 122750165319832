import React from 'react';
import Ecosystem from './components/Ecosystem';
import Map from 'components/Map';
import Experts from './components/Experts';
import CoursesAndEvents from 'components/CoursesAndEvents';
import {Container, Grid} from '@mui/material';
import Blog from './components/Blog';
import Subscription from 'components/Subscription';
import SubscribeForm from 'components/SubscribeForm';
import SmallContactCard from 'components/SmallContactCard';

const Landing = () => {
    return (
        <>
            <Ecosystem />
            <CoursesAndEvents />
            <Experts />
            <Subscription />
            <Container sx={{mt: 7}}>
                <Map />
            </Container>
            <Blog />
            <Container sx={{mt: 7}}>
                <Grid container spacing={{xs: 4, lg: 3}}>
                    <Grid item xs={12} lg={8}>
                        <SubscribeForm />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <SmallContactCard />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default Landing;
