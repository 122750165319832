import {Box, useMediaQuery} from '@mui/material';
import {LeftArrowIcon, RightArrowIcon} from 'assets/icons';
import React from 'react';

export const RightArrow = ({onClick, right}) => {
    const isSm = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const width = isSm ? 27 : 13.5;
    const height = isSm ? 50 : 25;
    return (
        <Box
            onClick={() => onClick()}
            sx={{
                color: 'primaryShift.main',
                cursor: 'pointer',
                position: 'absolute',
                right: right,
                // top: top || '50%',
                '&:hover': {opacity: 0.5}
                // transform: 'translate(0, -50%)'
            }}>
            <RightArrowIcon width={width} height={height} />
        </Box>
    );
};

export const LeftArrow = ({onClick, left}) => {
    const isSm = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const width = isSm ? 27 : 13.5;
    const height = isSm ? 50 : 25;
    return (
        <Box
            onClick={() => onClick()}
            sx={{
                color: 'primaryShift.main',
                cursor: 'pointer',
                position: 'absolute',
                left: left,
                // transform: 'translate(0, -50%)',
                // top: top || '50%',
                '&:hover': {opacity: 0.5}
            }}>
            <LeftArrowIcon width={width} height={height} />
        </Box>
    );
};

export const Dot = ({onClick, ...rest}) => {
    const {active} = rest;
    return (
        <Box onClick={() => onClick()} sx={{opacity: active ? 1 : 0.5, px: 1, color: 'primaryShift.main'}}>
            <svg width='10' height='9' viewBox='0 0 10 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M0.5 4.5C0.5 2.01472 2.51472 0 5 0C7.48528 0 9.5 2.01472 9.5 4.5C9.5 6.98528 7.48528 9 5 9C2.51472 9 0.5 6.98528 0.5 4.5Z'
                    fill='currentColor'
                />
            </svg>
        </Box>
    );
};
