import React, {createContext, useMemo, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import qs from 'qs';

export const TabsContext = createContext({});

export const PROFILE_TAB = {
    PERSONAL: 'personal',
    NETWORK: 'network',
    EDUCATION: 'education',
    MEDIA: 'media',
    WORK: 'work',
    INTERESTS: 'interests',
    SAFETY: 'safety',
    SUBSCRIPTION: 'subscription'
};

const TabsProvider = ({children}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const params = useMemo(() => qs.parse(searchParams.toString()), [searchParams]);
    const {tab} = params;

    const saveTab = (newValue) => {
        setSearchParams({tab: newValue});
    };
    return <TabsContext.Provider value={{tab, saveTab}}>{children}</TabsContext.Provider>;
};

export default TabsProvider;
