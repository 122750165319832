export const checkIsPreview = () => location.pathname.includes('preview');
export const checkIsPublic = () => location.pathname.includes('public');

export const cleanData = (object) =>
    Object.entries(object).reduce((a, [k, v]) => (v == null || v === '' ? a : ((a[k] = v), a)), {});

export const camelToSnake = (str) =>
    (
        str[0].toLowerCase() + str.slice(1, str.length).replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
    ).toUpperCase();

export const removeTagsFromString = (str) => str?.replace(/(<([^>]+)>)|(&nbsp;)/gi, '');

export const getRandomArrayElement = (arr) => {
    const random = Math.floor(Math.random() * arr.length);
    return arr[random];
};
