import React, {useState} from 'react';
import {IconButton, InputAdornment, TextField} from '@mui/material';
import {Controller} from 'react-hook-form';
import {IMaskInput} from 'react-imask';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import {StatusIcon} from 'assets/icons';
import {SUBSCRIPTION_STATUS} from 'constants/status';
import {LinkIcon} from './icons';

const MaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const {onChange, ...other} = props;
    return (
        <IMaskInput
            {...other}
            inputRef={ref}
            onAccept={(value) => {
                onChange({target: {name: props.name, value}});
            }}
            unmask
        />
    );
});

const TextFieldControl = ({
    placeholder,
    control,
    name,
    label,
    fullWidth = true,
    rules,
    disabled,
    multiline,
    rows,
    sx = {},
    maskProps,
    password = false,
    confirmed,
    linkIcon,
    startIcon,
    status,
    autoComplete
}) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({field, fieldState: {error}}) => (
                <TextField
                    {...field}
                    sx={{...sx, position: 'relative'}}
                    disabled={disabled}
                    id='textField'
                    multiline={multiline}
                    rows={rows}
                    type={!showPassword && password ? 'password' : 'text'}
                    value={field.value || ''}
                    helperText={error?.message}
                    placeholder={placeholder || label}
                    FormHelperTextProps={{sx: {mt: multiline ? 1.5 : 0.75}}}
                    fullWidth={fullWidth}
                    error={!!error}
                    autoComplete={autoComplete}
                    InputProps={{
                        inputComponent: maskProps?.mask ? MaskCustom : undefined,
                        inputProps: {...maskProps},
                        startAdornment: startIcon && (
                            <InputAdornment position={'start'} sx={{mr: 1}}>
                                {startIcon}
                            </InputAdornment>
                        ),
                        endAdornment: password ? (
                            <InputAdornment position='end'>
                                <IconButton
                                    aria-label='toggle password visibility'
                                    onClick={() => setShowPassword(!showPassword)}
                                    edge='end'>
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ) : confirmed ? (
                            <InputAdornment position='end'>
                                <CheckIcon sx={{fill: (theme) => theme.palette.success.main}} />
                            </InputAdornment>
                        ) : linkIcon ? (
                            <InputAdornment position='end'>
                                <LinkIcon sx={{fill: (theme) => theme.palette.systemShift[600]}} />
                            </InputAdornment>
                        ) : status ? (
                            <InputAdornment position='end'>
                                <StatusIcon color={SUBSCRIPTION_STATUS[status].color} />
                            </InputAdornment>
                        ) : undefined
                    }}
                />
            )}
        />
    );
};

export default TextFieldControl;
