import {FormControlLabel, FormHelperText, Radio, RadioGroup, Stack} from '@mui/material';
import {Controller} from 'react-hook-form';
import React from 'react';
import {RadioCheckedIcon, RadioUncheckedIcon} from './icons';

const RadioGroupControl = ({control, name, options, horizontal, disabled, sx, defaultValue}) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({field, fieldState: {error}}) => (
                <Stack direction={'column'} position={'relative'} width={'100%'}>
                    <RadioGroup
                        {...field}
                        aria-label='reason'
                        row={horizontal}
                        value={field.value || ''}
                        sx={{ml: 1.8}}>
                        {options.map((item, index) => (
                            <FormControlLabel
                                key={`radio-${index}`}
                                value={item.value}
                                control={<Radio icon={<RadioUncheckedIcon />} checkedIcon={<RadioCheckedIcon />} />}
                                label={item.label}
                                sx={{
                                    color: (theme) =>
                                        item.isCorrect === true
                                            ? theme.palette.success.main
                                            : item.isCorrect === false
                                            ? theme.palette.error.main
                                            : '',
                                    ...sx
                                }}
                                disabled={disabled}
                            />
                        ))}
                    </RadioGroup>
                    {error?.message && (
                        <FormHelperText error sx={{mb: 1}}>
                            {error?.message}
                        </FormHelperText>
                    )}
                </Stack>
            )}
        />
    );
};

export default RadioGroupControl;
