export const NOTIFICATIONS_TAB_TYPE_ENUM = {
    ALL: 'ALL',
    EVENTS: 'EVENTS',
    REQUESTS: 'REQUESTS',
    ACHIEVEMENTS: 'ACHIEVEMENTS',
    OTHER: 'OTHER'
};
export const NOTIFICATIONS_TAB_TYPE = {
    ALL: 'Все',
    EVENTS: 'События',
    REQUESTS: 'Заявки',
    ACHIEVEMENTS: 'Достижения',
    OTHER: 'Прочее'
};
export const NOTIFICATION_TYPE = {
    addedInChat: 'ТЫ ДОБАВЛЕН В ЧАТ',
    newAchievement: 'НОВОЕ ДОСТИЖЕНИЕ',
    newMediacenterMember: 'ПОПОЛНЕНИЕ КОМАНДЫ',
    newAnnouncement: 'НОВЫЙ АНОНС',
    newEventNote: 'НОВОЕ МЕРОПРИЯТИЕ',
    newMaterialsInEvent: 'НОВЫЕ МАТЕРИАЛЫ В СОБЫТИИ',
    newPost: 'НОВЫЙ МАТЕРИАЛ',
    newSubscription: 'НОВАЯ ПОДПИСКА',
    newEvent: 'НОВОЕ СОБЫТИЕ',
    youAreCurator: 'ТЫ - КУРАТОР',
    youAreModerator: 'ТЫ - МОДЕРАТОР',
    youAreExpert: 'ТЫ - ЭКСПЕРТ',
    subscriptionOverSoon: 'ПОДПИСКА ЗАКАНЧИВАЕТСЯ...',
    personalPermissionsChanged: 'ВЫДАНО ПРАВО',
    permissionsChanged: 'ВЫДАНО ПРАВО',
    freeEventPurchased: 'ПРИОБРЕТЕНИЕ СОБЫТИЯ',
    eventPurchased: 'ПРИОБРЕТЕНИЕ СОБЫТИЯ',
    subscriptionPurchased: 'ПРИОБРЕТЕНИЕ ПОДПИСКИ',
    requestStatusChanged: 'СМЕНА СТАТУСА ЗАЯВКИ',
    subscriptionNotPurchased: 'ОСТАЛСЯ ОДИН ШАГ...',
    eventNotPurchased: 'ОСТАЛСЯ ОДИН ШАГ...',
    noFeedback: 'ОСТАВЬ ОБРАТНУЮ СВЯЗЬ',
    noProgressEvent: 'НАЧИНАЙ ПРОХОЖДЕНИЕ',
    dataIsNotFilled: 'ЗАПОЛНИ СВОИ ДАННЫЕ',
    registrationCompleted: 'РЕГИСТРАЦИЯ ЗАВЕРШЕНА',
    requestNotSent: 'ЗАЯВКА НЕ ОТПРАВЛЕНА'
};
