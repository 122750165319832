export const EVENT_TYPE = {
    ONLINE_SCHOOL: 'Онлайн-школа',
    OFFLINE: 'Офлайн-событие',
    // INTERACTIVE: 'Интерактив',
    // INTENSIVE: 'Интенсив',
    WEBINAR: 'Вебинар',
    MASTER_CLASS: 'Мастер-класс',
    ONLINE_LECTURE: 'Лекция',
    // TEST: 'Тест',
    // FORM: 'Анкета',
    CONSULTATION: 'Консультация',
    ONLINE_CONFERENCE: 'Конференция',
    // ONLINE_EVENT: 'Онлайн-событие',
    CREATIVE_MEETING: 'Творческая встреча'
};

export const EVENT_NOTE_TYPE_ENUM = {OTHER: 'OTHER', PARTNERSHIP: 'PARTNERSHIP', SELF: 'SELF'};
export const EVENT_NOTE_TYPE = {
    OTHER: 'Прочее',
    PARTNERSHIP: 'Партнерское',
    SELF: 'Лаборатория медиа'
};
export const EVENT_PASSING_TYPE_ENUM = {WITHOUT_PROGRESS: 'WITHOUT_PROGRESS', LINEAR_PROGRESS: 'LINEAR_PROGRESS'};
export const FORMAT_TYPE = {ONLINE: 'Онлайн', OFFLINE: 'Офлайн'};

export const EVENTS_TYPE_OPTIONS = Object.entries(EVENT_TYPE).map(([key, displayName]) => ({
    id: key,
    displayName: displayName
}));
export const FORMAT_TYPE_OPTIONS = Object.entries(FORMAT_TYPE).map(([key, displayName]) => ({
    id: key,
    displayName: displayName
}));

export const RESOURCE_TYPE_ENUM = {SUBSCRIPTION_PERIOD: 'SUBSCRIPTION_PERIOD', TARIFF: 'TARIFF'};
export const ACQUIRING_TYPE_ENUM = {DOLYAME: 'DOLYAME', TINKOFF: 'TINKOFF'};
export const ACCESS_TYPE_ENUM = {CLOSE: 'CLOSE', OPEN: 'OPEN'};
export const ACCESS_AFTER_END_ENUM = {ALL: 'ALL', LIMITED: 'LIMITED', NOTHING: 'NOTHING'};

export const PROGRAM_TYPE_ENUM = {
    LINKED_EVENT: 'LINKED_EVENT',
    LINKED_EVENT_NOTE: 'LINKED_EVENT_NOTE',
    FILTRATION: 'FILTRATION',
    NOTE: 'NOTE'
};

export const PROGRAM_FAVORITE_TYPE = {
    LINKED_EVENT: 'EVENT',
    LINKED_EVENT_NOTE: 'EVENT_NOTE',
    NOTE: 'PROGRAM_NOTE'
};
export const EXPERT_TYPE = {CURATOR: 'CURATOR', EXPERT: 'EXPERT', MODERATOR: 'MODERATOR'};

export const ELEMENT_TYPE_ENUM = {
    ABOUT: 'ABOUT',
    CERTIFICATE: 'CERTIFICATE',
    CHAT: 'CHAT',
    CREATIVE_TASK: 'CREATIVE_TASK',
    FEEDBACK: 'FEEDBACK',
    FILE: 'FILE',
    FORM: 'FORM',
    HEADING: 'HEADING',
    IMAGE_WITH_SIGNATURE: 'IMAGE_WITH_SIGNATURE',
    NEWS: 'NEWS',
    PAGE_HEADER: 'PAGE_HEADER',
    PROGRAM: 'PROGRAM',
    PROGRESS: 'PROGRESS',
    QUESTION: 'QUESTION',
    SLOT: 'SLOT',
    TEXT: 'TEXT',
    VIDEO: 'VIDEO'
};
