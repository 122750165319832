export default {
    typography: {
        fontFamily: 'Roboto',
        h1: {
            textTransform: 'uppercase',
            fontSize: 56,
            lineHeight: '64px',
            fontWeight: 700
        },
        h2: {
            textTransform: 'uppercase',
            fontSize: 48,
            lineHeight: '56px',
            fontWeight: 700,
            '@media (max-width:900px)': {
                fontSize: 32,
                lineHeight: '40px'
            }
        },
        h3: {
            textTransform: 'uppercase',
            fontSize: 40,
            lineHeight: '48px',
            fontWeight: 700,
            '@media (max-width:900px)': {
                fontSize: 32,
                lineHeight: '40px'
            }
        },
        h4: {
            textTransform: 'uppercase',
            fontSize: 32,
            lineHeight: '40px',
            fontWeight: 700,
            '@media (max-width:900px)': {
                fontSize: 20,
                lineHeight: '28px'
            }
        },
        h5: {
            textTransform: 'uppercase',
            fontSize: 24,
            lineHeight: '32px',
            fontWeight: 700,
            '@media (max-width:900px)': {
                fontSize: 16,
                lineHeight: '24px'
            }
        },
        h6: {
            textTransform: 'uppercase',
            fontSize: 20,
            lineHeight: '28px',
            fontWeight: 700
        },
        p1: {
            fontSize: 20,
            lineHeight: '28px',
            fontWeight: 400,
            '@media (max-width:900px)': {
                fontSize: 16,
                lineHeight: '24px'
            }
        },
        p2: {
            fontSize: 18,
            lineHeight: '24px',
            fontWeight: 400,
            '@media (max-width:900px)': {
                fontSize: 14,
                lineHeight: '20px'
            }
        },
        p3: {
            fontSize: 16,
            lineHeight: '22px',
            fontWeight: 400,
            '@media (max-width:900px)': {
                fontSize: 14,
                lineHeight: '20px'
            }
        },
        p4: {
            fontSize: 14,
            lineHeight: '20px',
            fontWeight: 400
        }
    }
};
