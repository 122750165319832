import React from 'react';

export const MARKERS = [
    {
        id: 136,
        name: 'СМАГУ',
        address: 'пр. Ленина, д. 61, г. Барнаул, 656049',
        latitude: 53.348129,
        longitude: 83.775868
    },
    {
        id: 137,
        name: 'SG Media',
        address: 'ул. Трофимова, д. 27, г. Бийск, 659305',
        latitude: 52.515344,
        longitude: 85.158331
    },
    {
        id: 138,
        name: 'ASAU-Media',
        address: 'пр. Красноармейский, д. 73, г. Барнаул, 656049',
        latitude: 53.338436,
        longitude: 83.77294
    },
    {
        id: 139,
        name: 'Пресс-центр',
        address: 'ул. им. А. Юрина, д. 277, г. Барнаул, 656055',
        latitude: 53.3313,
        longitude: 83.774341
    },
    {
        id: 140,
        name: 'ZOOM',
        address: 'ул. Молодежная, д. 55, г. Барнаул, 656031',
        latitude: 53.341742,
        longitude: 83.760956
    }
];

export const COURSES = [
    {
        title: 'Медиапул',
        to: '/',
        description:
            'Поможем начать карьеру в индустрии. Научим работать с разными форматами, на практике покажем, как создавать контент и продвигать медиапроекты. ',
        id: 1
    },
    {
        title: 'Сообщества',
        to: '/',
        description: 'Единомышленники, обмен опытом и создание совместных проектов с медийщиками со всей России. ',
        id: 2
    },
    {
        title: 'Продакшн',
        to: '/',
        description:
            'MEDIALAB.PRODUCTION — создаем видеоконтент, проводим выездные съемки и организовываем онлайн-трансляции. ',
        id: 3
    },
    {
        title: 'Образовательные проекты',
        to: '/',
        description: 'Онлайн-школы, интенсивы, офлайн-события, вебинары и форумы — выбирай форматы и начинай обучение.',
        id: 4
    },
    {
        title: 'Развитие медиацентров',
        to: '/',
        description:
            'Лаборатории медиа объединяет студенческие СМИ и молодежные медиа России. Мы создаем медиацентры в вузах, обучаем команды и продвигаем проекты на федеральном уровне. ',
        id: 5
    }
];

export const EXPERTS = [
    {
        name: 'Сергей Епихин',
        icon: 'https://files.robotbull.com/medialab/user_avatars/a455e5b9-da08-4ed5-8a42-e23a1cfacf46.jpg',
        post: 'Куратор направления подкасты и автор подкаста Бородакаст'
    },
    {
        name: 'Алина Еськова',
        icon: 'https://files.robotbull.com/medialab/user_avatars/5449b7b8-f82c-44f3-8817-c3f4f5c57410.jpeg',
        post: 'продюсер подкаста «Это разве продакшн?»'
    },
    {
        name: 'Гриша Пророков',
        icon: 'https://files.robotbull.com/medialab/user_avatars/a0943e70-e81a-4de3-84b3-ce055632642e.jpg',
        post: 'Блитц энд Чипс'
    },
    {
        name: 'Артур Кулаков',
        icon: 'https://files.robotbull.com/medialab/user_avatars/2cd2c6c0-a182-4515-8850-b8fe0a5c7aaa.jpeg',
        post: 'Куратор направления подкасты и автор подкаста Бородакаст'
    },
    {
        name: 'Антон Фамилия',
        icon: 'https://files.robotbull.com/medialab/user_avatars/604a2585-f054-4d9f-bbd0-9eec3b5693b1.jpeg',
        post: 'Bear Grills'
    },
    {
        name: 'Ещё Ктотович',
        icon: 'https://files.robotbull.com/medialab/user_avatars/eb87b061-dcef-42cb-abc2-4a65684331f4.jpeg',
        post: 'Bear Grills'
    }
];
