import {Button, Stack} from '@mui/material';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {ROUTES} from 'constants/routes';

const AuthButtons = ({sx}) => {
    const navigate = useNavigate();
    return (
        <Stack spacing={2} alignItems={'flex-start'} sx={{mb: 1.5, ...sx}}>
            <Button variant={'contained'} fullWidth onClick={() => navigate(ROUTES.REG)}>
                Регистрация
            </Button>
            <Button variant={'outlined'} fullWidth onClick={() => navigate(ROUTES.AUTH)}>
                Войти
            </Button>
        </Stack>
    );
};

export default AuthButtons;
