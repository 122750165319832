import {Box, Container, Link as MuiLink, Stack, Typography} from '@mui/material';
import {Link, useNavigate} from 'react-router-dom';
import {ROUTES} from 'constants/routes';
import {THEMES} from 'constants/themes';
import LogoBlack from 'assets/images/logo-white.png';
import LogoWhite from 'assets/images/logo-black.png';
import {NETWORK} from 'constants/network';
import NavList from 'layouts/MainLayout/NavList';
import moment from 'moment/moment';
import AuthButtons from 'layouts/MainLayout/AuthButtons';
import React, {useContext} from 'react';
import settingsContext from 'context/settingsContext';
import {AuthContext} from 'context/authContext';
import {saveAs} from 'file-saver';
import {COMMON_DATA} from 'layouts/MainLayout/Footer/index';


const DesktopFooter = ({privacyPolicyFile, publicOfferFile}) => {
    const navigate = useNavigate();
    const {data} = useContext(AuthContext);

    const {settings} = useContext(settingsContext);

    const ADDRESSES = [
        {title: 'Адрес', descr: `Лаборатория медиа\n${COMMON_DATA.address}`},
        {title: '', descr: `${COMMON_DATA.email}\n${COMMON_DATA.phone}`}
    ];

    return (
        <Container>
            <Stack
                flexGrow={1}
                direction={'column'}
                sx={{
                    my: 6,
                    width: '100%',
                    '& .MuiTypography-root': {paddingTop: 0, color: 'system.600'}
                }}>
                <Stack direction={'row'} spacing={2} justifyContent={'space-between'} flexGrow={1}>
                    <Stack flexGrow={1}>
                        <Stack direction={'row'} justifyContent={'space-between'} mb={7}>
                            <Stack flexGrow={1}>
                                <Box
                                    component={Link}
                                    to={ROUTES.LANDING}
                                    sx={{mb: 3}}
                                    onClick={() => window.scrollTo(0, 0)}>
                                    <img
                                        src={settings.theme === THEMES.DARK ? LogoBlack : LogoWhite}
                                        height={50}
                                        alt={'logo'}
                                    />
                                </Box>
                                <Box sx={{'& :first-of-type': {ml: 0}}}>
                                    {NETWORK.map((item, index) => (
                                        <MuiLink
                                            href={item.link}
                                            key={index}
                                            sx={{mx: 1, '& svg': {height: '12px', color: 'systemShift.900'}}}>
                                            {item.icon}
                                        </MuiLink>
                                    ))}
                                </Box>
                            </Stack>
                            <Stack
                                alignItems={'flex-start'}
                                flexGrow={1}
                                sx={{my: 0, '& .MuiListItem-root': {paddingTop: 0}}}>
                                <NavList />
                            </Stack>
                        </Stack>

                        <Typography variant={'p4'}>
                            (с) 2021-{moment().format('YYYY')} АНО «Лаборатория медиа»
                        </Typography>
                    </Stack>
                    <Stack flexGrow={1}>
                        <Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                            spacing={2}
                            mb={11}
                            flexGrow={1}>
                            <Stack justifyContent={'space-between'} flexGrow={1}>
                                {ADDRESSES.map((item, index) => (
                                    <Box key={index} sx={{mb: 2}}>
                                        <Typography noWrap fontWeight={500}>
                                            {item.title}
                                        </Typography>
                                        <Typography fontWeight={500} noWrap sx={{whiteSpace: 'pre-wrap'}}>
                                            {item.descr}
                                        </Typography>
                                    </Box>
                                ))}
                            </Stack>
                            <Stack flexGrow={1}>
                                {privacyPolicyFile && (
                                    <Typography
                                        noWrap
                                        fontWeight={500}
                                        sx={{whiteSpace: 'pre-wrap', cursor: 'pointer'}}
                                        onClick={() =>
                                            saveAs(privacyPolicyFile.url, privacyPolicyFile.name)
                                        }>
                                        Политика
                                        <br />
                                        конфиденциальности
                                    </Typography>
                                )}
                                {
                                    publicOfferFile && (
                                        <Typography
                                            noWrap
                                            fontWeight={500}
                                            sx={{whiteSpace: 'pre-wrap', cursor: 'pointer', mt: 1}}
                                            onClick={() =>
                                                saveAs(publicOfferFile.url, publicOfferFile.name)
                                            }>
                                            Условия
                                            <br />
                                            публичной оферты
                                        </Typography>
                                    )
                                }
                                <Typography
                                    noWrap
                                    fontWeight={500}
                                    sx={{whiteSpace: 'pre-wrap', cursor: 'pointer', mt: 1}}
                                    onClick={() => window.open(COMMON_DATA.reportsLink)}>
                                    Отчеты
                                </Typography>
                                <Typography
                                    noWrap
                                    fontWeight={500}
                                    sx={{whiteSpace: 'pre-wrap', cursor: 'pointer', mt: 1}}
                                    onClick={() => navigate(ROUTES.ORGANIZATION_INFORMATION)}>
                                    Сведения об образовательной
                                    <br />
                                    организации
                                </Typography>
                            </Stack>
                            {data?.me ? (
                                <Stack spacing={1} flexGrow={1}>
                                    <Typography
                                        fontWeight={500}
                                        color={(theme) =>
                                            location.pathname === ROUTES.PROFILE
                                                ? theme.palette.systemShift[900] + ' !important'
                                                : theme.palette.systemShift[600]
                                        }
                                        onClick={() => navigate(ROUTES.PROFILE)}
                                        sx={{cursor: 'pointer'}}>
                                        Личный кабинет
                                    </Typography>
                                    <Typography
                                        fontWeight={500}
                                        color={(theme) => theme.palette.systemShift[900] + '!important'}
                                        onClick={() => navigate(ROUTES.TRAJECTORY)}
                                        sx={{
                                            color: (theme) =>
                                                location.pathname === ROUTES.TRAJECTORY
                                                    ? theme.palette.systemShift[900] + ' !important'
                                                    : theme.palette.system[600],
                                            cursor: 'pointer'
                                        }}>
                                        Мои курсы
                                    </Typography>
                                </Stack>
                            ) : (
                                <AuthButtons sx={{flexGrow: 1}} />
                            )}
                        </Stack>
                        <Typography sx={{maxWidth: 900}} variant={'p4'}>
                            Образовательные услуги оказываются Автономной некоммерческой организацией
                            «Лаборатория&nbsp;медиа» на&nbsp;основании Лицензии №&nbsp;Л035-01298-77/00665244
                            от&nbsp;20&nbsp;июля&nbsp;2023.
                            <br /> Образовательные услуги оказываются в соответствии с Федеральным законом
                            от&nbsp;04.05.2011&nbsp;№ 99-ФЗ
                            «О&nbsp;лицензировании&nbsp;отдельных&nbsp;видов&nbsp;деятельности».
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Container>
    )
}

export default DesktopFooter;
