import React from 'react';
import {Button, Grid, Stack, Typography} from '@mui/material';
import {useQuery} from '@apollo/client';
import {GET_INTERESTS} from 'graphql/autocomplete/getInterests';

const InterestsFilter = ({form}) => {
    const {setValue, watch} = form;
    const watchInterests = watch('interests');

    const {data} = useQuery(GET_INTERESTS);
    const optionInterests = data?.interests;

    const addInterest = (id) => {
        if (!!watchInterests?.includes(id)) {
            setValue(
                'interests',
                watchInterests.filter((i) => i !== id)
            );
        } else {
            setValue('interests', [...watchInterests, id]);
        }
    };
    return (
        <Stack>
            <Typography mb={3} sx={{textTransform: 'uppercase'}}>
                Направления
            </Typography>
            <Grid container spacing={1} minHeight={100}>
                {optionInterests?.map((item) => (
                    <Grid item xs={'auto'} key={item?.id}>
                        <Button
                            size={'small'}
                            sx={{px: 1.5}}
                            onClick={() => addInterest(item.id)}
                            variant={!!watchInterests?.includes(item?.id) ? 'contained' : 'outlined'}>
                            {item?.name}
                        </Button>
                    </Grid>
                ))}
            </Grid>
        </Stack>
    );
};

export default InterestsFilter;
