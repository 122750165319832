import {Link, Stack, Typography, useMediaQuery} from '@mui/material';
import background from 'pages/About/icons/background-card.svg';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailIcon from '@mui/icons-material/Mail';
import Network from 'pages/About/components/Network';
import React from 'react';

const SmallContactCard = () => {
    const isLg = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    return (
        <>
            {isLg ? (
                <Stack
                    spacing={3}
                    borderRadius={'20px'}
                    sx={{
                        backgroundColor: 'background.card',
                        backgroundImage: `url(${background}), url(${background})`,
                        backgroundPosition: 'left -150px top -120px, right -50px top 270px',
                        backgroundRepeat: 'no-repeat'
                    }}
                    p={5}>
                    <Typography variant={'h4'}>Связаться с&nbsp;нами:</Typography>
                    <Typography variant={'p1'}>
                        Отправить запрос на разработку программы и обсудить совместный проект
                    </Typography>
                    <Stack spacing={2}>
                        <Stack direction={'row'} spacing={1}>
                            <LocalPhoneIcon sx={{color: 'primary.main'}} />
                            <Link variant={'p1'} href={'tel:+79996745561'}>
                                + 7 (999) 674-55-61
                            </Link>
                        </Stack>
                        <Stack direction={'row'} spacing={1}>
                            <MailIcon sx={{color: 'primary.main'}} />
                            <Link variant={'p1'} href='mailto:partner@medialab.team'>
                                partner@medialab.team
                            </Link>
                        </Stack>
                    </Stack>
                    <Network />
                </Stack>
            ) : (
                <>
                    <Typography variant={'h4'} mb={2}>
                        Контакты:
                    </Typography>
                    <Stack spacing={1} sx={{textTransform: 'uppercase'}}>
                        <Typography>Отправить запрос на разработку программы и обсудить совместный проект</Typography>
                        <Typography component={Link} href={'tel:+79996745561'} sx={{textDecoration: 'none'}}>
                            + 7 (999) 674-55-61
                        </Typography>
                        <Typography
                            component={Link}
                            href='mailto:partner@medialab.team'
                            sx={{textDecoration: 'none', color: 'systemShift.900'}}>
                            partner@medialab.team
                        </Typography>
                    </Stack>
                </>
            )}
        </>
    );
};

export default SmallContactCard;
