import React, {useEffect, useState} from 'react';
import {Stack, SvgIcon, Tooltip, Typography} from '@mui/material';
import {ACHIEVEMENT_TYPE, RHOMB_TYPE} from 'constants/achievement';
import CustomModal from 'components/modal/CustomModal';
import {useNotifications} from '@novu/notification-center';
import {NOTIFICATIONS_TAB_TYPE_ENUM} from 'constants/notifications';
import {getRandomArrayElement} from 'helpers';

const AchievementModal = ({achievement, open, setOpen, isNew}) => {
    const {markAsSeen} = isNew ? useNotifications({storeId: NOTIFICATIONS_TAB_TYPE_ENUM.ACHIEVEMENTS}) : {};
    const closeModal = () => {
        if (isNew && achievement) {
            markAsSeen(achievement?.['_id']).then((data) => {});
        }
        setOpen(false);
    };
    const textArray = ['Я - молодец', 'Ура! Новое достижение', 'Класс', 'Здорово'];
    const [textButton, setTextButton] = useState(getRandomArrayElement(textArray));
    useEffect(() => {
        setTextButton(getRandomArrayElement(textArray));
    }, [achievement]);
    const Content = () => {
        return (
            <Stack alignItems={'center'} sx={{position: 'relative'}} gap={2}>
                {achievement.maxLevel > 1 && !isNew && (
                    <Stack direction={'row'} gap={1} sx={{mb: 1, mt: '8px !important'}}>
                        {achievement?.currentAchievementLevels?.map((el) => (
                            <Tooltip title={el.description}>
                                <SvgIcon
                                    component={el.isDone ? RHOMB_TYPE.default : RHOMB_TYPE.disabled}
                                    inheritViewBox={true}
                                    sx={{fill: 'none', cursor: 'pointer'}}
                                />
                            </Tooltip>
                        ))}
                    </Stack>
                )}
                <SvgIcon
                    component={
                        isNew || achievement.currentLevel > 0
                            ? ACHIEVEMENT_TYPE[achievement?.picture]?.default
                            : ACHIEVEMENT_TYPE[achievement?.picture]?.disabled
                    }
                    inheritViewBox={true}
                    sx={{width: 128, height: 128}}
                />
                <Typography variant={'p3'} sx={{textTransform: 'uppercase'}}>
                    {achievement.name}
                </Typography>
                <Typography variant={'p3'} color={'systemShift.700'} sx={{textAlign: 'center'}}>
                    {achievement.description}
                </Typography>
            </Stack>
        );
    };
    return (
        <CustomModal
            open={open}
            onClose={() => closeModal()}
            title={isNew ? 'Новое достижение' : 'Достижение'}
            content={<Content />}
            closeText={isNew ? textButton : 'Закрыть'}
            closeButtonVariant={'contained'}
            sxDialog={{maxWidth: 400}}
        />
    );
};

export default AchievementModal;
