import React from 'react';
import {Divider} from '@mui/material';

const CustomDivider = ({orientation, sx}) => {
    return (
        <Divider
            orientation={orientation}
            flexItem
            sx={{
                ...sx,
                border: (theme) => '1px solid' + theme.palette.primary.medium,
                borderRadius: '5px',
                backgroundColor: 'primary.medium'
            }}
        />
    );
};

export default CustomDivider;
