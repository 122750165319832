import {Box, Stack, Typography} from '@mui/material';
import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {removeTagsFromString} from 'helpers';
import {useNotifications} from '@novu/notification-center';
import {NOTIFICATION_TYPE} from "constants/notifications";

const NotificationCard = ({notification, isLast}) => {
    const [currentNotification, setCurrentNotification] = useState(notification);
    const {markAsSeen} = useNotifications();

    useEffect(() => {
        setCurrentNotification(notification);
    }, [notification]);

    const readNotification = () => {
        if (!currentNotification?.seen)
            markAsSeen(currentNotification['_id']).then(() => {
                setCurrentNotification({...currentNotification, seen: true});
            });
    };

    const title = currentNotification?.payload?.subject || NOTIFICATION_TYPE[currentNotification.templateIdentifier];
    const content = currentNotification?.payload?.content || currentNotification?.content;
    const url = currentNotification?.payload?.checkout || currentNotification.cta?.data?.url;

    const handleNavigate = () => {
        window.open(url);
    };

    return (
        <Box
            p={3}
            sx={{
                backgroundColor: !currentNotification?.seen && 'systemShift.200',
                borderBottom: !isLast && 'solid 2px',
                borderColor: 'systemShift.400'
            }}
            onMouseEnter={readNotification}>
            <Typography variant='p3' mb={2} fontWeight={500}>
                {title}
            </Typography>
            <Typography variant={'p3'} mb={2}>
                {removeTagsFromString(content)}
            </Typography>
            <Stack justifyContent={'space-between'} direction={'row'}>
                <Typography color={'grey.500'}>
                    {`${moment(currentNotification?.createdAt).format('DD.MM.YY')} В ${moment(
                        currentNotification?.createdAt
                    ).format('HH:mm')}`}
                </Typography>
                {!!url && (
                    <Typography sx={{cursor: 'pointer'}} color={'primary'} onClick={handleNavigate}>
                        ПОДРОБНЕЕ
                    </Typography>
                )}
            </Stack>
        </Box>
    );
};

export default NotificationCard;
