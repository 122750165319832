import {THEMES} from 'constants/themes';
import merge from 'lodash/merge';
import {createTheme} from '@mui/material';
import typography from './typography';
import {whiteTheme} from './whiteTheme';
import {blackTheme} from './blackTheme';
import {baseTheme} from './baseTheme';

const themesOptions = {
    [THEMES.LIGHT]: whiteTheme,
    [THEMES.DARK]: blackTheme
};

const baseOptions = {
    ...typography,
    ...baseTheme
};

const createAppTheme = (config = {}) => {
    let themeOptions = themesOptions[config.theme];
    if (!themeOptions) {
        console.warn(new Error(`The theme ${config.theme} is not valid`));
        themeOptions = themesOptions[THEMES.LIGHT];
    }
    return createTheme(merge({}, baseOptions, themeOptions));
};
export default createAppTheme;
