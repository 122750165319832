import React from 'react';
import {Avatar, Box, IconButton, SvgIcon, Typography} from '@mui/material';
import getInitials from 'helpers/getInitials';
import {ExitIcon} from 'assets/icons';
import {useNavigate} from 'react-router-dom';
import SubscriptionStatus from 'components/SubscriptionStatus';
import {ROUTES} from 'constants/routes';

const LoginUser = ({me, onExit}) => {
    const navigate = useNavigate();
    const navigateToProfile = () => navigate(ROUTES.PROFILE);

    return (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box sx={{position: 'relative'}}>
                <Avatar
                    sx={{mr: 1, backgroundColor: 'purple.main', cursor: 'pointer'}}
                    size='large'
                    aria-label='account of current user'
                    aria-controls='menu-appbar'
                    aria-haspopup='true'
                    src={me?.avatarFile?.url}
                    onClick={navigateToProfile}
                    color='inherit'>
                    {me ? getInitials(me?.firstName + ' ' + me?.lastName) : ''}
                </Avatar>
                <Box sx={{position: 'absolute', bottom: -12, right: 4}}>
                    <SubscriptionStatus />
                </Box>
            </Box>
            <Typography color={'systemShift.900'} sx={{mr: 2, cursor: 'pointer'}} onClick={navigateToProfile}>
                {me?.firstName}
            </Typography>
            <IconButton onClick={onExit} sx={{p: 0}}>
                <SvgIcon component={ExitIcon} inheritViewBox sx={{cursor: 'pointer'}} />
            </IconButton>
        </Box>
    );
};

export default LoginUser;
