import React, {useContext, useState} from 'react';
import {ListItem, Typography} from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom';
import {AuthContext} from 'context/authContext';
import {ROUTES} from 'constants/routes';
import Services from './Services';

const NavList = ({sx, isHeader}) => {
    const location = useLocation();
    const {data} = useContext(AuthContext);
    const navigate = useNavigate();
    const conditionColor = (condition) => (theme) =>
        condition ? theme.palette.systemShift[900] + ' !important' : theme.palette.system[600];

    const tabColor = (to) => {
        if (to === ROUTES.ABOUT + '?page=contacts') return conditionColor(location.pathname + location.search === to);
        else return conditionColor(location.pathname === to);
    };

    const [openServices, setOpenServices] = useState(false);

    const NAV_ROUTES = [
        // {title: 'Главная', to: ROUTES.LANDING},
        {title: 'О нас', to: ROUTES.ABOUT},
        {title: 'Курсы и события', to: ROUTES.COURSES_AND_EVENTS},
        {title: 'Подписка', to: ROUTES.SUBSCRIPTION},
        {title: 'Блог', to: ROUTES.BLOG},
        {
            title: 'Услуги',
            action: (event) => {
                event.stopPropagation();
                setOpenServices(true);
            }
        },
        {title: 'Календарь', to: ROUTES.CALENDAR},
        {title: 'Контакты', to: ROUTES.ABOUT + `?page=contacts`}
        // {title: 'Наши эксперты', to: '#'} // /experts
    ];
    return (
        <>
            {NAV_ROUTES.map((item, index) => (
                <ListItem
                    onClick={(event) => (item.to ? navigate(item.to) : item.action(event))}
                    disableGutters
                    key={`list-item-${index}`}
                    sx={{...sx, cursor: 'pointer'}}>
                    <Typography noWrap color={tabColor(item.to)} fontWeight={500}>
                        {item.title}
                    </Typography>
                </ListItem>
            ))}
            {isHeader && data?.me && (
                <ListItem onClick={() => navigate(ROUTES.TRAJECTORY)} disableGutters sx={{cursor: 'pointer'}}>
                    <Typography fontWeight={500} noWrap color={tabColor(ROUTES.TRAJECTORY)}>
                        Мои курсы
                    </Typography>
                </ListItem>
            )}

            <Services {...{openServices, setOpenServices}} />
        </>
    );
};

export default NavList;
