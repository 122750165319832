import React from 'react';
import {Box, CircularProgress} from '@mui/material';

const CircularLoading = ({children, loading, sx, size = 40}) => {
    return (
        <>
            {loading ? (
                <Box sx={{...sx, display: 'flex', justifyContent: 'center'}}>
                    <CircularProgress size={size} />
                </Box>
            ) : (
                children
            )}
        </>
    );
};

export default CircularLoading;
