import {gql} from '@apollo/client';

export const ME = gql`
    query me {
        me {
            id
            firstName
            middleName
            lastName
            birthDate
            numericId
            avatarFile {
                id
                url
            }
            city {
                id
                externalId
                name
                region {
                    id
                    externalId
                    name
                    country {
                        id
                        externalId
                        name
                    }
                }
            }
            tinodeUserSecret
            subscriptionStatus
            userSubscriptionPeriods {
                id
                endsAt
                status
                subscriptionPeriod {
                    id
                    duration
                    cost
                    subscription {
                        title
                        description
                    }
                    subscriptionId
                }
            }
            email {
                id
                address
                isConfirmed
            }
            phone {
                id
                number
            }
        }
    }
`;
