import React, {useContext} from 'react';
import {StatusIcon} from 'assets/icons';
import {SUBSCRIPTION_STATUS} from 'constants/status';
import {Box, Tooltip} from '@mui/material';
import {AuthContext} from 'context/authContext';

const SubscriptionStatus = ({status}) => {
    const {lastSubscriptionPeriod} = useContext(AuthContext);
    const userSubscriptionStatus = status || lastSubscriptionPeriod?.status;

    return userSubscriptionStatus ? (
        <Tooltip title={`Статус подписки: ${SUBSCRIPTION_STATUS[userSubscriptionStatus]?.label}`}>
            <Box>
                <StatusIcon color={SUBSCRIPTION_STATUS[userSubscriptionStatus]?.color} />
            </Box>
        </Tooltip>
    ) : (
        <></>
    );
};

export default SubscriptionStatus;
