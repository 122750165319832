import React from 'react';
import {Box, CardMedia, Stack, styled, Typography} from '@mui/material';
import noAvatar from 'assets/images/noavatar.png';

const ExpertCard = ({expert}) => {
    return (
        <Stack spacing={2} key={expert?.id}>
            <Box sx={{pt: '100%', height: 0, overflow: 'hidden', position: 'relative'}}>
                <StyledCard component='img' image={expert.avatarFile?.url || noAvatar} alt={expert.firstName} href='' />
            </Box>
            <Stack direction={'row'} spacing={1} alignItems={'center'} flexWrap>
                <Typography variant='h6'>
                    {expert.firstName} {expert.lastName}
                </Typography>
            </Stack>
            <Typography>{expert.about}</Typography>
        </Stack>
    );
};
const StyledCard = styled(CardMedia)(() => ({
    borderRadius: '20px',
    filter: 'grayscale(100%)',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0
}));
export default ExpertCard;
