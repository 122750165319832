import React from 'react';
import {Box, useMediaQuery} from '@mui/material';
import {useQuery} from '@apollo/client';
import {GET_FOOTER_FILES} from 'graphql/landing/getFooterFiles';
import DesktopFooter from 'layouts/MainLayout/Footer/DesktopFooter';
import MobileFooter from 'layouts/MainLayout/Footer/MobileFooter';

export const COMMON_DATA = {
    reportsLink: 'https://disk.yandex.ru/d/QGhCrXGzp8xkOw',
    phone: '+7 (999) 875-01-32',
    email: 'info@medialab.team',
    address: 'Москва, ул.Гиляровского, д.50'
}


const Footer = () => {
    const isLg = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    const {data: footerFiles} = useQuery(GET_FOOTER_FILES)

    const privacyPolicyFile = footerFiles?.privacyPolicy?.file;
    const publicOfferFile = footerFiles?.publicOffer?.file;

    return (
        <Box sx={{textTransform: 'uppercase', borderTop: (theme) => '1px solid' + theme.palette.systemShift['400']}}>
            {isLg ? (
                <DesktopFooter
                    privacyPolicyFile={privacyPolicyFile}
                    publicOfferFile={publicOfferFile}
                />
            ) : (
                <MobileFooter
                    privacyPolicyFile={privacyPolicyFile}
                    publicOfferFile={publicOfferFile}
                />
            )}
        </Box>
    );
};

export default Footer;
