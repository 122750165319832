import React from 'react';
import {Box, Link} from '@mui/material';
import {NETWORK} from 'constants/network';

const Network = () => {
    return (
        <Box>
            {NETWORK.map((item, index) => (
                <Link href={item.link} key={index} sx={{mr: 2, '& svg': {height: '20px', color: 'system.600'}}}>
                    {item.icon}
                </Link>
            ))}
        </Box>
    );
};

export default Network;
