import {gql} from '@apollo/client';

export const GET_FOOTER_FILES = gql`
    query getFooterFiles {
        publicOffer: landingInformation(type: PUBLIC_OFFER) {
            id
            file {
                id
                url
                name
            }
        }
        privacyPolicy: landingInformation(type: PRIVACY_POLICY) {
            id
            file {
                id
                url
                name
            }
        }
    }
`;
