import React, {createContext, useState} from 'react';
import CustomModal from 'components/modal/CustomModal';

export const ConfirmModalContext = createContext({});

export const ConfirmModalProvider = ({children}) => {
    const [open, setOpen] = useState(false);
    const [modalContent, setModalContent] = useState({
        title: '',
        text: '',
        actionText: '',
        closeText: '',
        handleClick: async () => {},
        onClose: async () => {},
        loading: null
    });
    const {title, text, actionText, closeText, handleClick, onClose, loading} = modalContent;

    const handleOpenModal = ({title, text, actionText, closeText, handleClick, onClose = async () => {}, loading}) => {
        setModalContent({title, text, actionText, closeText, handleClick, onClose, loading});
        setOpen(true);
    };
    const handleCloseModal = () => {
        onClose().then(() => setOpen(false));
    };
    const onSubmit = () => {
        handleClick().then(() => {
            handleCloseModal();
        });
    };

    return (
        <ConfirmModalContext.Provider value={{handleOpenModal}}>
            {children}
            <CustomModal
                open={open}
                handleClick={onSubmit}
                onClose={handleCloseModal}
                title={title}
                actionText={actionText}
                content={text}
                closeText={closeText}
                disableBackdrop
            />
        </ConfirmModalContext.Provider>
    );
};
