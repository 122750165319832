export const CARD_TYPE = {
    EVENT: 'EVENT',
    EVENT_NOTE: 'EVENT_NOTE'
};
export const CARD_OUTPUT_TYPE_ENUM = {
    COURSES: 'COURSES',
    LANDING: 'LANDING'
};

export const VARIANT_BUTTON = {
    DARK: 'contained',
    LIGHT: 'outlined'
};
