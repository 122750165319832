import * as React from 'react';
import {useContext} from 'react';
import {Box, Icon, Link, Stack, styled, Tooltip, useMediaQuery} from '@mui/material';
import Typography from '@mui/material/Typography';
import {THEMES} from 'constants/themes';
import SettingsContext from 'context/settingsContext';
import {Clusterer, Map as YMap, Placemark, YMaps} from 'react-yandex-maps';
import markerIcon from 'assets/icons/marker.svg';
import {useQuery} from '@apollo/client';
import {GET_MEDIA_CENTERS} from 'graphql/landing/getMediaCenters';
import CircularLoading from './loading/CircularLoading';
import {MarkerOutlineIcon} from 'assets/icons';
import {Link as RouterLink} from 'react-router-dom';
import {ROUTES} from 'constants/routes';
import {formatNumber} from 'helpers/formaters';
import {MEDIACENTERS_TITLE} from 'constants/global';

const Map = () => {
    const {settings} = useContext(SettingsContext);
    const {data, loading, error} = useQuery(GET_MEDIA_CENTERS);
    const mediaCenters = data?.mediaCenters || [];
    const isMd = useMediaQuery((theme) => theme.breakpoints.up('md'));
    return (
        <Box>
            <Stack
                direction={{xs: 'column', md: 'row'}}
                spacing={3}
                justifyContent={{xs: 'center', md: 'space-between'}}
                alignItems={'center'}
                mb={{xs: 3, lg: 7}}>
                <Typography variant='h3' sx={{textAlign: {xs: 'center', md: 'start'}}}>
                    Объединяем медиацентры
                </Typography>
                <Tooltip title={`С нами уже ${formatNumber(mediaCenters.length, MEDIACENTERS_TITLE)}`}>
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                        <Typography variant={'h4'} sx={{cursor: 'default'}} color={'primaryShift.main'}>
                            {mediaCenters.length}
                        </Typography>
                        {isMd ? (
                            <Icon
                                component={MarkerOutlineIcon}
                                sx={{color: 'primaryShift.main', width: 28, height: 28}}
                            />
                        ) : (
                            <Typography variant={'h4'}>
                                {formatNumber(mediaCenters.length, MEDIACENTERS_TITLE, true)}
                            </Typography>
                        )}
                    </Stack>
                </Tooltip>
                {!isMd && (
                    <Stack spacing={1} alignItems={'center'} sx={{textTransform: 'uppercase'}}>
                        <Typography>Нет твоего медиацентра на карте? </Typography>
                        <RouterLink to={ROUTES.FORMS_CREATE_MEDIA_CENTER}>
                            <Link color={'primary'}>
                                <Typography>Отправь нам запрос</Typography>
                            </Link>
                        </RouterLink>
                    </Stack>
                )}
            </Stack>
            <CircularLoading loading={loading || error}>
                <StyledMap {...{settings}}>
                    <YMaps>
                        <YMap
                            width={'100%'}
                            height={'30rem'}
                            defaultState={{
                                center: [60, 94.15],
                                zoom: 3,
                                controls: ['zoomControl']
                            }}
                            modules={['control.ZoomControl', 'geoObject.addon.balloon', 'geoObject.addon.hint']}
                            options={{suppressMapOpenBlock: true, suppressObsoleteBrowserNotifier: true}}>
                            <Clusterer options={{groupByCoordinates: false, clusterIconColor: '#7A45EA'}}>
                                {mediaCenters.length !== 0 &&
                                    mediaCenters.map((center) => (
                                        <Placemark
                                            key={center.id}
                                            geometry={[center.latitude, center.longitude]}
                                            properties={{
                                                balloonContent: `${center.address}<br/>${center.name.toUpperCase()}`,
                                                hintContent: center.name.toUpperCase()
                                            }}
                                            options={{
                                                iconLayout: 'default#image',
                                                iconImageHref: markerIcon
                                            }}
                                        />
                                    ))}
                            </Clusterer>
                        </YMap>
                    </YMaps>
                </StyledMap>
            </CircularLoading>
            {isMd && (
                <Stack direction={'row'} spacing={0.5} sx={{textTransform: 'uppercase'}}>
                    <Typography>Нет твоего медиацентра на карте?</Typography>
                    <RouterLink to={ROUTES.FORMS_CREATE_MEDIA_CENTER}>
                        <Link color={'primary'}>
                            <Typography>отправь нам запрос</Typography>
                        </Link>
                    </RouterLink>
                </Stack>
            )}
        </Box>
    );
};

const StyledMap = styled(Box, {shouldForwardProp: (prop) => prop !== 'settings'})(({settings}) => ({
    '& .ymaps-2-1-79-ground-pane': {
        filter:
            settings.theme === THEMES.DARK
                ? 'grayscale(1) invert(90%) sepia(1%) saturate(95%) hue-rotate(202deg) brightness(108%) contrast(98%)'
                : 'grayscale(1) sepia(1%) saturate(100%) hue-rotate(180deg) brightness(108%)'
    },
    '& .ymaps-2-1-79-copyright__logo': {display: 'none'},
    marginBottom: 24
}));

export default Map;
