import React, {useContext, useMemo} from 'react';
import {Box, Container, Stack, Typography} from '@mui/material';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useMutation} from '@apollo/client';
import {TOAST_TYPE, ToastContext} from 'context/toastContext';
import {InputWithLabel} from 'components/inputs';
import {INPUT_TYPE} from 'components/inputs/InputWithLabel';
import {LoadingButton} from '@mui/lab';
import {RESET_PASSWORD} from 'graphql/auth/mutation/resetPassword';
import {useNavigate, useSearchParams} from 'react-router-dom';
import qs from 'qs';
import {ROUTES} from 'constants/routes';
import {RESET_PASSWORD_VALIDATION} from './validation';
import {RESET_FIELDS} from './fields';
import ERROR_TYPE from 'constants/errorType';

const ResetPassword = () => {
    const [resetPassword, {loading}] = useMutation(RESET_PASSWORD);
    const [searchParams] = useSearchParams();
    const {token} = useMemo(() => qs.parse(searchParams.toString()), [searchParams]);
    const navigate = useNavigate();
    const {addToast} = useContext(ToastContext);
    const form = useForm({
        resolver: yupResolver(RESET_PASSWORD_VALIDATION)
    });

    const {control, reset, handleSubmit} = form;

    const onSubmit = (data) => {
        resetPassword({
            variables: {
                token: token,
                password: data.againPassword
            }
        })
            .then(() => {
                reset();
                addToast({type: TOAST_TYPE.SUCCESS, text: 'Пароль успешно изменен'});
                navigate(ROUTES.AUTH);
            })
            .catch((err) => {
                const error = err?.graphQLErrors.find(
                    (err) => err?.extensions?.exception?.response?.type === ERROR_TYPE.TOKEN_EXPIRED
                );
                if (!!error) navigate(ROUTES.AUTH);
                addToast({type: TOAST_TYPE.ERROR, text: err.message});
            });
    };
    return (
        <Container>
            <Box sx={{flexGrow: 1, py: 6, justifyContent: 'center', display: 'flex'}}>
                <Box sx={{maxWidth: 500}}>
                    <Box textAlign={'center'}>
                        <Typography variant={'h4'}>Восстановление пароля</Typography>
                        <Typography sx={{mt: 3, mb: 6}} variant={'p3'}>
                            Укажите новый пароль для вашей учетной записи
                        </Typography>
                    </Box>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={2}>
                            {RESET_FIELDS.map((item, index) => (
                                <InputWithLabel
                                    key={index}
                                    type={INPUT_TYPE.TEXT}
                                    {...item}
                                    control={control}
                                    password
                                    labelWidth={{md: 170, xs: 85}}
                                />
                            ))}
                        </Stack>

                        <LoadingButton
                            variant='contained'
                            size={'large'}
                            type={'submit'}
                            sx={{mt: 6, width: '100%'}}
                            loading={loading}>
                            Сохранить
                        </LoadingButton>
                    </form>
                </Box>
            </Box>
        </Container>
    );
};

export default ResetPassword;
