import React, {createContext, useEffect, useRef, useContext, useState} from 'react';
import {ApiService} from '@novu/client';
import {AuthContext} from 'context/authContext';
import {useSocket, useUnseenCount} from '@novu/notification-center';

export const NotificationContext = createContext({});

export const NotificationProvider = ({children}) => {
    const {setUnseenCount} = useUnseenCount();
    const [notifications, setNotifications] = useState([]);
    const [onlyUnread, setOnlyUnread] = useState(false);
    const [loading, setLoading] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const apiService = useRef(new ApiService(process.env.REACT_APP_NOVU_BACKEND_URL));
    const {data} = useContext(AuthContext);
    const {socket} = useSocket();

    useEffect(async () => {
        if (data?.me?.id) {
            const {token} = await apiService.current.initializeSession(
                process.env.REACT_APP_NOVU_APPLICATION_IDENTIFIER,
                data?.me?.id
            );
            await apiService.current.setAuthorizationToken(token);
            setInitialized(true);
        }
    }, [data?.me]);

    const getNotifications = async ({page = 0, tab, onlyUnread}) => {
        if (!initialized) return;
        setLoading(true);
        const res = await apiService.current.getNotificationsList(page, {
            feedIdentifier: tab === 'ALL' ? undefined : tab,
            seen: onlyUnread ? !onlyUnread : undefined
        });
        setLoading(false);
        return res ? Array.isArray(res) ? res : res?.data : [];
    };

    useEffect(() => {
        if (socket && data?.me) {
            socket.on('unseen_count_changed', (data) => {
                setUnseenCount(data.unseenCount);
            });
        }
        return () => {
            if (socket) {
                socket.off('unseen_count_changed');
            }
        };
    }, [socket]);

    return (
        <NotificationContext.Provider
            value={{
                apiService: apiService.current,
                getNotifications,
                notifications,
                setNotifications,
                loading,
                onlyUnread,
                setOnlyUnread,
                initialized
            }}>
            {children}
        </NotificationContext.Provider>
    );
};
